import React from 'react'
import Footer from './Footer'
import './CSS/style.css'
import LstGt from './Image/less_than_icon.png'
import codeOneImg from './Image/code_one_img.jpg'
import codeTwoImg from './Image/code_two_img.jpg'
import codeImg from './Image/code_img.jpg'



const Code = () => {
    document.title = 'Let’s talk about code | Martian Blogs'
    return (
        <div class="Martian_Blog_page">



            <div class="previous_page">

                <a href="https://martiancorporation.com/blogs">
                    <img src={LstGt} alt="back-icon" />
                    <h4>
                        Back to Martian Blogs
                    </h4>
                </a>
            </div>

            <header class="header">
                <h2>
                    Let’s talk about code
                </h2>

                <div className="social_media">
                    <a href="http://fb.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://linkedin.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://x.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z">
                            </path>
                        </svg>
                    </a>
                </div>
            </header>



            <div class="main-blog">
                <div class="header_img">
                    <img src={codeImg} alt="code_image" />
                </div>

                <div class="blog-content">
                    <p class="main-point">
                        What is code?
                    </p>

                    <p>
                        Code is the <span class="para-highlight">
                            language of computers
                        </span>. It is the set of instructions that tell a computer what to do.
                        It's like a recipe for
                        a computer, specifying every step needed to perform a particular task, Code is used to create
                        everything from websites
                        and apps to video games and operating systems.
                    </p>

                    <p>
                        These instructions are written in programming languages designed to be understood by humans and
                        computers.
                    </p>
                    <p>
                        Programming languages come in many flavors, from high-level languages like Python and Java, which
                        are easier for humans
                        to read and write, to low-level languages like Assembly, which are closer to the language that
                        computers understand.
                        Regardless of the language used, the goal is <span class="para-highlight">
                            to translate human intentions
                        </span>into a form that a
                        <span class="para-highlight">
                            computer can execute
                        </span>.
                    </p>

                    <p class="main-point">
                        Why should you learn to code?
                    </p>
                    <p>
                        Code is everywhere, and its importance in our lives cannot be overstated. There are many reasons why
                        you should learn to
                        code:
                    </p>

                    <div class="flex">
                        <p>
                            <span class="para-highlight">
                                Driving Technological Advancements
                            </span>: Code is the backbone of technological innovation. It's what allows us to create
                            new software, develop cutting-edge hardware, and build sophisticated systems like self-driving cars,
                            artificial
                            intelligence, and augmented reality.
                        </p>


                        <img src={codeOneImg} alt="code_imgage" />
                    </div>
                    <p>
                        <span class="para-highlight">
                            Empowering Businesses
                        </span>: In the business world, code plays a crucial role in automating tasks, improving efficiency,
                        and reaching customers
                        through digital channels. From e-commerce platforms to data analytics, code underpins the operations
                        of modern
                        enterprises.
                    </p>

                    <p>
                        <span class="para-highlight">
                            Enabling Connectivity
                        </span>: The internet and the entire digital ecosystem rely on code to function. Code enables us to
                        communicate, access
                        information, and connect with others across the globe.
                    </p>

                    <p>
                        <span class="para-highlight">
                            Solving Complex Problems
                        </span>: Code is instrumental in solving complex problems in various fields, such as healthcare
                        (with medical software and
                        simulations), science (with data analysis and simulations), and engineering (with CAD software and
                        simulations).
                    </p>



                    <p>
                        Here are a few reasons you learn to code:
                    </p>

                    <ul>
                        <li>
                            Coding is a valuable skill that is in high demand.
                        </li>
                        <li>
                            Coding can help you solve problems and think creatively.
                        </li>

                        <li>
                            Coding can give you a better understanding of how the internet and technology work.
                        </li>
                        <li>
                            Coding can help you build your own digital products and services.
                        </li>
                        <li>
                            Coding can be fun and rewarding.
                        </li>
                    </ul>



                    <p class="main-point">
                        How to learn to code?
                    </p>

                    <p>
                        If you are interested in learning to code, there are many resources available to help you get
                        started. There are <span class="para-highlight">
                            online
                            courses
                        </span>, <span class=" para-highlight">
                            tutorials
                        </span>,and books that can teach you the basics of coding. You can also find many <span class="para-highlight">
                            coding
                            communities
                        </span> online
                        and in person where you can get help and support from other coders.
                    </p>



                    <p class="main-point">
                        Tips for learning to code:
                    </p>
                    <p>
                        Here are some tips for learning to code:
                    </p>

                    <div class="flex">
                        <img src={codeTwoImg} alt="code img" />

                        <ul>
                            <li>
                                Start with a beginner-friendly programming language, such as C or Python.
                            </li>
                            <li>
                                Find a good online course or tutorial that teaches you the basics of coding.
                            </li>
                            <li>
                                Practice coding regularly. The more you code, the better you will become at it.
                            </li>

                        </ul>

                    </div>
                    <ul>
                        <li>
                            Don't be afraid to ask for help. There are many online and in-person communities where you can
                            get help from other
                            coders.
                        </li>
                    </ul>

                    <p class="main-point">
                        What can you build with code?
                    </p>

                    <p>
                        Once you have learned the basics of code, you can start to build your <span class="para-highlight">
                            digital products
                        </span> and <span class="para-highlight">
                            services
                        </span>.
                        You can create
                        websites, apps, video games, and more. You can also use your coding skills to <span class="para-highlight">
                            solve problems
                        </span> and
                        <span class="para-highlight">
                            automate tasks
                        </span>.
                    </p>

                    <p class="Quotes">
                        "In the world of code, conversations are written in algorithms, and ideas speak in functions and variables." <br /> <span>
                            - Unknown
                        </span>
                    </p>

                    <p class="main-point">
                        Conclusion:
                    </p>

                    <p>
                        Code is a powerful tool for creativity and problem-solving. Code is the invisible force shaping our
                        digital world. It's
                        <span class="para-highlight">
                            the tool that empowers us to solve problems
                        </span>, innovate, and create the technology that makes our
                        lives easier and more
                        connected. As technology continues to advance, our reliance on code will only grow, making it a
                        topic worthy of
                        discussion and exploration.
                    </p>

                    <p>
                        Whether you're a seasoned programmer or someone who's never written a line of code, it's important
                        to recognize the role
                        code plays in our lives. <span class="para-highlight">
                            Learning more about code can lead to a deeper understanding of the digital
                            landscape
                        </span> and a
                        greater appreciation for the incredible feats it allows us to achieve. So, let's keep talking about
                        code and continue to
                        marvel at its transformative power.
                    </p>
                    <p class="para-highlight-last">
                        Is code help for human being?
                    </p>

                </div>

            </div>

            <Footer />

        </div>
    )
}

export default Code
