import React from 'react'
// import '../CSS/Career/Career.css'
import '../CSS/DarkMode/Career/Career.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import artImg from '../../Images/Image/artImg.png'
import BackTopBar from '../Buttons/BackTopBar'
import BackTop from '../Buttons/BackTop'

const Career = () => {
    return (
        <>
        <BackTopBar/>
        <BackTop/>
            <Navbar width="80%" />
            <div className="ParentRapper" id='Career' >


                <div className='careerCont'>
                    <div className="careerHeroSection">
                        <div className='headingSection'>
                            <div></div>
                            <div>
                                <h1>Welcome to your Martian career</h1>
                                <a href='mailto:career@martiancorporation.com' ><button>Send Your CV</button></a>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="joinMartianSectionWrapper">
                        <div className="joinMartianSection">
                            <div className="textInfoCont">
                                <h1>Why Join Martian?</h1>
                                <p className='desc'>Join us at Martian for a tech journey like no other. Dive into innovation, challenge limits, and grow in a creative culture. Shape the future with us and become part of a visionary community. Elevate your career at Martian, where excellence is the norm.</p>
                            </div>
                            <img src={artImg} alt="artImg" />
                        </div>
                    </div>
                    <div className="yourCareerCont">
                        <p className='title'>Your Career</p>
                        <div className='yourCareerWrapper'>
                            <div className="interviewProcess">
                                <div className='textWrapper'>
                                    <p>Interview preparation</p>
                                    <h1>Learn about our interview process</h1>
                                    <p>We are excited to get to know you and help you get to know us through our interview process.</p>
                                </div>
                            </div>
                            <div className="campus">
                                <div className='textWrapper'>
                                    <p>For Internship</p>
                                    <h1>Martian on campus</h1>
                                    <p>We’re looking for exceptional people from colleges and universities around the world.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer width="100%"/>
                </div>
            </div>

        </>
    )
}

export default Career
