import React, { useEffect, useState } from 'react'
import '../CSS/Navbar/Navbar.css'
import Logo from '../../Images/Image/LogoMartianDark.png'
import getInTouch from '../../Images/Icons/getInTouch.svg'
import "../CSS/DarkMode/Navbar/Navbar.css"
import { HashLink } from 'react-router-hash-link'
const Navbar = (prop) => {
  const [NavSlide, SetNavSlide] = useState(false)
  const [scrolling, Setscrolling] = useState(0)

  const [NavSize, SetNavSize] = useState(
    window.innerWidth <= 768 ? "90%" : "80%"
  )
  const Hamber = () => {
    SetNavSlide(!NavSlide)
    // document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:scroll;")
  }
  const HamberLink = () => {
    SetNavSlide(false)
    // document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:scroll;")
  }
  useEffect(() => {
    if (window.scrollY > 0) {
      document.getElementById("Navbar").classList.add("navBarHightChanger")
    } else {
      document.getElementById("Navbar").classList.remove("navBarHightChanger")

    }
    if (NavSlide) {
      document.getElementsByTagName('body')[0].setAttribute("style", "overflow:hidden !important;")

    } else {

      document.getElementsByTagName('body')[0].removeAttribute("style")
    }
    // eslint-disable-next-line
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        SetNavSize("90%");
      } else {
        SetNavSize("80%");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [NavSlide, scrolling])

  window.addEventListener("scroll", () => {
    Setscrolling(window.scrollY)
  })

  return (
    <>
      <div className='NavbarCont' id='Navbar'>
        <div className="navRapper" style={{ "--Nav-width": NavSize }}>

          <div className="logoSection">
            <HashLink to='/#BackTop'>
              <img src={Logo} alt="MartianLogo" />
              {/* <p>Martian Coporation</p> */}
            </HashLink>
          </div>
          <div className={NavSlide ? "listWrapper" : "listWrapper listWrapper2"}>
            <ul>
              <li><HashLink to="/#services" onClick={() => { HamberLink() }}>Services</HashLink></li>
              <li><HashLink to="/#products" onClick={() => { HamberLink() }}>Products</HashLink></li>
              <li><HashLink to="/#sum" onClick={() => { HamberLink() }}>SUM</HashLink></li>
              <li><HashLink to="/career#BackTop" onClick={() => { HamberLink() }}>Careers</HashLink></li>
              <li><HashLink to="/about#BackTop" onClick={() => { HamberLink() }}>About us</HashLink></li>
              <li><HashLink to="/blogs#BackTop" onClick={() => { HamberLink() }}>Martian Blogs</HashLink></li>
              <li>

                <HashLink to="/contact#BackTop" className='getintouch' onClick={() => { HamberLink() }}>

                  <p>Get in touch</p>
                  <img src={getInTouch} alt="GetIntuch" className='ContactIcon' />
                </HashLink>
              </li>
            </ul>
          </div>
          <button className={NavSlide ? "hamber hamber2" : "hamber"} onClick={() => Hamber()}>
            <span></span>
            <span></span>
            <span></span>
          </button>

        </div>
      </div >

    </>
  )
}

export default Navbar