import { React, useState } from 'react'
import Buiding from "../../Images/Icons/building.svg"
import RArrow from "../../Images/Icons/arrow-right.svg"
import ContactSvg from "../../Images/Icons/contact.svg"
import CalenderSvg from "../../Images/Icons/calender.svg"
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import ContactModal from './ContactModal'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../CSS/DarkMode/Contact/Contact.css'
import BackTopBar from '../Buttons/BackTopBar'
import BackTop from '../Buttons/BackTop'

const Contact = () => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [NavSize, SetNavSize] = useState("80%")
  const [Interested, SetInterested] = useState({
    branding: false,
    webDesign: false,
    appdesign: false,
    illustrations: false,
    HTML: false,
    SiteFromScratch: false,
    App: false
  });

  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    company: "",
    comment: "",
    budget: "",
    interest: {}
  })


  const InterestedChange = (e) => {
    SetInterested({ ...Interested, [e.target.name]: e.target.checked })
  }


  const funcOnchanche = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const FormData =
  {
    "fName": formData.fName,
    "lName": formData.lName,
    "email": formData.email,
    "phone": formData.phone,
    "company": formData.company,
    "comment": formData.comment,
    "budget": formData.budget,
    "interestField": Interested
  }


  const funcFormSubmit = (e) => {
    setLoading(true)
    setFormData(FormData);

    axios.post(`${URL}/contact/concern`, FormData).then((res) => {
      toast.success(res.data)
      setFormData(
        {
          fName: "",
          lName: "",
          email: "",
          phone: "",
          company: "",
          comment: "",
          budget: "",
        }
      )
      setLoading(false)
    }).catch((err) => {
      toast.error(err.response.data)
      setLoading(false)
    })
  }

  window.addEventListener("load", () => {

    if (window.innerWidth <= 768) {
      SetNavSize("90%")
    } else {
      SetNavSize("80%")

    }
  })
  window.addEventListener("resize", () => {

    if (window.innerWidth <= 768) {
      SetNavSize("90%")
    } else {
      SetNavSize("80%")

    }
  })

  return (
    <>
      <BackTopBar />
      <Navbar width={NavSize} />
      <BackTop />

      <div className='ParentRapper' id='Contact'>
        <ToastContainer />
        <div className="contact-page">
          <div className="contact-body">
            <div className="contact-left">
              <h1 className="heading">Get in touch with us</h1>

              <div className="contact-interested">
                <h4> I'm interested in...  </h4>
                <div className="interested-items">
                  <div className="interested-upper" >
                    <input type="checkbox" className='interested-btn-upper customInputButton' name="Web Development" value="Web Development" onInput={InterestedChange} />
                    <input type="checkbox" className='interested-btn-upper customInputButton' name="App Development" value="App Development" onInput={InterestedChange} />
                    <input type="checkbox" className='interested-btn-upper customInputButton' name="Software Development" value="Software Development" onInput={InterestedChange} />
                    <input type="checkbox" className='interested-btn-upper customInputButton' name="Maintenance" value="Maintenance" onInput={InterestedChange} />

                  </div>
                  <div className="interested-lower-sec">

                    <input type="checkbox" className='interested-btn-lower customInputButton' name="StartUp Support" id="interested" value="StartUp Support" onChange={InterestedChange} />
                    <input type="checkbox" className='interested-btn-lower customInputButton' name="Brand Building" id="interested" value="Brand Building" onChange={InterestedChange} />
                    <input type="checkbox" className='interested-btn-lower customInputButton' name="Others" id="interested" value="Others" onChange={InterestedChange} />

                  </div>
                </div>
              </div>
              <div className="contact-form">
                <h2 className="form-heading">
                  Enter you details
                </h2>
                <form method='post' id="ContactSubmitForm">
                  <div className="name-input-sec">
                    <input type="text" name="fName" id="firstName" className="contact-input name-input" placeholder='First name' value={formData.fName} onChange={funcOnchanche} />
                    <input type="text" name="lName" id="lastName" className="contact-input name-input" placeholder='Last name' value={formData.lName} onChange={funcOnchanche} />
                  </div>
                  <input type="email" name="email" id="contactEmail" className="contact-input" placeholder='Email' value={formData.email} onChange={funcOnchanche} />
                  <div className="phoneCompany">
                    <input type="number" name="phone" id="contactPhone" className="contact-input" placeholder='Phone' value={formData.phone} onChange={funcOnchanche} />
                    <input type="text" name="company" id="contact-company" className="contact-input" placeholder='Company' value={formData.company} onChange={funcOnchanche} />
                  </div>
                  <textarea name="comment" id="descripton" className='' cols="20" rows="7" placeholder='Write here if you have extra commeents' value={formData.comment} onChange={funcOnchanche} ></textarea>
                  <div className="project-details">
                    <h2 className="project-details-heading">Project budget (USD)</h2>
                    <div className="budget-lists" onChange={funcOnchanche}>


                      <input type="radio" className='budget-fix-btn customInputButton' name="budget" value="1k-2k" onChange={funcOnchanche} />
                      <input type="radio" className='budget-fix-btn customInputButton' name="budget" value="2k-5k" onChange={funcOnchanche} />
                      <input type="radio" className='budget-fix-btn customInputButton' name="budget" value="5k-10k" onChange={funcOnchanche} />
                      <input type="radio" className='budget-fix-btn customInputButton' name="budget" value="10k-50k" onChange={funcOnchanche} />
                      <input type="radio" className='budget-fix-btn customInputButton' name="budget" value="&gt;50k" onChange={funcOnchanche} />
                    </div>
                    <div className="contact-submit">
                      <button type="button" onClick={funcFormSubmit} disabled={loading}>{loading ? "Loading..." : "Submit"}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="contact-right">
              <div className="extra-service">
                <div className="extra-service-card">
                  <div className="service-cards-left">
                    <img src={Buiding} alt="svg" />
                  </div>
                  <div className="service-content">
                    <h3>Not an enterprise?</h3>
                    <p>Find a plan that's right for you.</p>
                    <a href="/"><span>View Plans & Pricing</span><img src={RArrow} alt="" /> </a>
                  </div>
                </div>
                <div className="extra-service-card">
                  <div className="service-cards-left">
                    <img src={ContactSvg} alt="svg" />
                  </div>
                  <div className="service-content">
                    <h3>Contact us directly</h3>
                    <a href="mailto:office@martiancorporation.com" className='contact-links'>office@martiancorporation.com</a>
                    <a href='tel:+91 8585074646' className='contact-links'>+91 8585074646</a>
                    <a href="https://wa.me/+918585074646?text=Hello," target="_blank" rel="noreferrer"><span>Whatsapp Directly</span><img src={RArrow} alt="" /> </a>
                  </div>
                </div>
                <div className="extra-service-card">
                  <div className="service-cards-left">
                    <img src={CalenderSvg} alt="svg" />
                  </div>
                  <div className="service-content">
                    <h3>Schedule a meeting </h3>
                    <p className='contact-links'>you can schedule a meeting as per your time </p>
                    <Link onClick={() => setOpenModal(true)} ><span> Schedule a meeting</span><img src={RArrow} alt="" /></Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <ContactModal openModal={openModal} setOpenModal={setOpenModal} />
        <Footer width="100%" />
      </div>
    </>
  )
}


export default Contact