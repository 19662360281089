import React from 'react'
import '../CSS/Services/Services.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import BackTop from '../Buttons/BackTop'
import BackTopBar from '../Buttons/BackTopBar'

const Services = () => {
  return (
    <div>
      <BackTopBar />
      <BackTop />
      <Navbar />
      <h1>Services Page Under Progress.....</h1>
      <Footer />
    </div>
  )
}

export default Services
