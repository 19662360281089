import React from 'react'
import Footer from './Footer'
import WebsiteMain from './Image/Website_main.jpg'
import websiteForth from './Image/website-forth.jpg'
import websiteSec from './Image/website-sec.jpg'
import wordpress from './Image/wordpress.png'
import LstGt from './Image/less_than_icon.png'
import websites from './Image/website.jpg'
import './CSS/style.css'

const Web = () => {
  document.title = 'LET’S TALK ABOUT WEBSITES | Martain Blogs'
  return (
    <div className="Martian_Blog_page">
      <div className="previous_page">

        <a href="https://martiancorporation.com/blogs">
          <img src={LstGt} alt="back_icon" />
          <h4>
            Back to Martian Blogs
          </h4>
        </a>
      </div>


      <header className="header">
        <h2>
          LET’S TALK ABOUT WEBSITES
        </h2>

        <div className="social_media">
          <a href="http://fb.com">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
              <path
                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
              </path>
            </svg>
          </a>
          <a href="http://linkedin.com">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
              <path
                d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z">
              </path>
            </svg>
          </a>
          <a href="http://x.com">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
              <path
                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z">
              </path>
            </svg>
          </a>
        </div>
      </header>


      <div className="main-blog">
        <div className="header_img">
          <img src={WebsiteMain} alt="cloud" />
        </div>

        <div className="blog-content">
          <p>
            The easy, need to know guide for what websites actually are:
          </p>
          <p className="main-point">
            WHAT ACTUALLY ARE WEBSITES?
          </p>

          <p>
            A website is like a book, but on your computer or phone. Inside a website, there can be all sorts of
            things, like words,
            pictures, videos, and links to other places on the internet. People make websites to share
            information, sell things, or
            just have fun. So, when you go online and visit a site like <a href="https://www.amazon.com/"
              target="blank">"www.amazon.com"</a>, you're checking out a website!

          </p>

          <p className="main-point">
            THE EVOLUTION OF WEBSITES:
          </p>


          <ul>
            <li>
              Started as text-only in the '90s
            </li>
            <li>
              Added images in the mid-'90s.
            </li>
            <li>
              Used tables for layout late '90s.
            </li>
            <li>
              Flash brought multimedia.
            </li>
            <li>
              CSS improved design early 2000s.
            </li>
            <li>
              Web 2.0 made them interactive.
            </li>
            <li>
              Went mobile-friendly in the late 2000s.
            </li>
            <li>
              HTML5 boosted rich media.
            </li>
            <li>
              Mobile-first design became key.
            </li>
            <li>
              SPAs and web apps emerged.
            </li>
            <li>
              Voice assistants and AI joined.
            </li>
            <li>
              PWAs blended web and apps.
            </li>
            <li>
              Modern designs in the 2020s.
            </li>

          </ul>




          <p className="main-point">
            CATEGORIES OF WEBSITES:
          </p>
          <p className="main-point">
            <span className="para-highlight">
              STATIC WEBSITES:
            </span>
          </p>
          <p>
            Those websites <span className="para-highlight">
              whose content and images do not change
            </span> are known as static websites. Their content stays the same for
            everyone who visits. It's like <span className="para-highlight">
              reading a book
            </span>,
          </p>
          <div className="flex">
            <p>
              where the words and pictures do not change. Static websites load quickly
              because they are so simple, and rarely face problems. They are easy to make and don’t require
              any fancy coding
              skills.
              You can design it, write the content and put it all together. <span className="para-highlight">
                Static websites are written using HTML
              </span></p>
            <img src={websiteSec} alt="website-sec" />
          </div>
          <p>
            but don’t worry
            you do not need to be a computer in order to understand it. Many small business websites and
            personal blogs are
            static. They're great for sharing basic info like contact details, services, or a portfolio of work.
          </p>
          <p className="main-point">
            <span className="para-highlight">
              DYNAMIC WEBSITES:
            </span>

          </p>
          <p>
            Dynamic websites offer <span className="para-highlight">
              unique content to visitors
            </span> and are ever changing. The content can change depending on<span className="para-highlight">
              one’s age
            </span>,
            <span className="para-highlight">
              time
            </span> of the day,<span className="para-highlight">
              geographical location
            </span>,<span className="para-highlight">
              language settings
            </span>, etc. You can interact with the website – leave comments, fill
            out forms, or play games. Some dynamic sites know who you are and show you things you might like.
            Dynamic websites use
            special tools and programming to work their magic. Databases and scripts make it all happen. Unlike
            static sites, <span className="para-highlight">
              making
              dynamic websites
            </span> can be tricky. You often need some coding know- <span className="para-highlight">
              how to build and maintain them
            </span>. Think of social media
            sites like Facebook or Twitter. The posts and updates are always changing, and you can chat with
            friends. Online stores
            with shopping carts are also dynamic because they keep track of what you want to buy.
          </p>




          <p className="main-point">
            TYPES OF WEBSITES:
          </p>


          <p>
            <span className="para-highlight">
              Informational Websites:
            </span>:
            These are like digital libraries. You can find facts, news, and all sorts of knowledge here. Think
            Wikipedia or news
            sites.
          </p>
          <p>
            <span className="para-highlight">
              E-commerce Websites
            </span>:
            These are online stores where you can buy stuff, like clothes, gadgets, or even groceries. Amazon
            and Flipkart are big
            examples.
          </p>
          <div className="flex">
            <img src={websites} alt="website" />
            <div>
              <p>
                <span className="para-highlight">
                  Social Media Websites
                </span>:
                Places where you can connect with friends, share photos, and chat. Facebook and Instagram
                are super popular ones.
              </p>
            </div>
          </div>
          <p>
            <span className="para-highlight">
              Blog Websites
            </span>:
            People write about their thoughts, experiences, and passions here. It's like a personal diary but on
            the internet.
          </p>
          <p>
            <span className="para-highlight">
              Educational Websites
            </span>:
            They help you learn things, from math and science to languages and history. Khan Academy is a great
            example.
          </p>

          <p>
            <span className="para-highlight">
              Entertainment Websites
            </span>:
            Want to watch videos, play games, or listen to music? YouTube, Netflix, and Spotify are your go to
            sites.
          </p>
          <p>
            <span className="para-highlight">
              Government Websites
            </span>:
            These are official pages where you can find laws, services, and information from your local or
            national government.
          </p>
          <p>
            <span className="para-highlight">
              Portfolio Websites
            </span>:
            Artists, writers, and professionals use these to show off their work and skills to potential
            employers or clients.
          </p>

          <p>
            <span className="para-highlight">
              Search Engines
            </span>:
            Google, Bing, and Yahoo are like magical maps for finding things on the internet.
          </p>
          <p>
            <span className="para-highlight">
              Forum Websites
            </span>:
            Places where people have discussions about specific topics. Reddit is one big forum site.
          </p>



          <p className="main-point">
            WANT TO BUILD YOUR OWN WEBSITE WITHOUT ANY CODING? HERE ARE A FEW TIPS!
          </p>


          <p>
            Check out this amazing video by “Website Learners” on YouTube, <a
              href="https://youtu.be/XV1cOGaZUq0?si=HdMwX5ngl_iL2QLK" target="blank">
              "Website-learn"
            </a>. We’ve
            also summarized the various steps for you. Let’s build you a website!
          </p>
          <p>
            <span className="para-highlight">
              Step 1
            </span> - Create a free account in Profreehost or any free hosting server. Fill in your details and
            submit.
          </p>

          <p>
            <span className="para-highlight">
              Step 2
            </span>- Get your free domain. A domain is the name of your website. This is the name you type in to
            get to a website.
            E.g. <a href="https://www.amazon.com/" target="blank">www.amazon.com</a>, <a
              href="https://www.facebook.com/" target="blank">www.facebook.com</a>.Once you've got your free
            domain, it's time for step 3.
          </p><p>
            <span className="para-highlight">
              Step 3
            </span> - Install WordPress on your domain. <span className="para-highlight">
              WordPress is the platform
            </span> which you are going to use in order to create your
            website and this lets you build your website easily, <span className="para-highlight">
              without any coding or programming
            </span>.
          </p><div className="flex">
            <p>
              Super easy right! In the control
              panel/ settings go to “softwares” and click on “Apps Installer” and install WordPress. Enter
              your user’s name and
              password which will be used to login to your website.
            </p>
            <img src={wordpress} alt="wordpress-img" />
          </div><p> The link to your website will be generated, which means that it is
            <span className="para-highlight">
              LIVE on the internet
            </span>.
          </p>
          <p className="main-point">
            Once you've launched your website, it's time for the next step - to start building your website.
          </p>
          <p>
            <span className="para-highlight">
              Step 1
            </span> - Login to your website by entering the name of your website and then "/wp-admin".
            Eg. <a href="http://amazon.com/wp-admin" target="blank">"amazon.com/wp-admin"</a>.
          </p >
          <p>
            <span className="para-highlight">
              Step 2
            </span> - You'll be taken to the dashboard from where you can control your website. Choose a design
            for your website. Go
            to "<span className="para-highlight">
              appearance
            </span>" on your dashboard and click on "<span className="para-highlight">
              themes
            </span>". Click on "<span className="para-highlight">
              add new theme
            </span>" and install any theme of your choice.
            <span className="para-highlight">
              Active
            </span> the theme. In order to choose your design, click on "<span className="para-highlight">
              Get started
            </span>". Select any page builder of your choice from
            the options given, for example choose "<span className="para-highlight">
              Elementor
            </span>", which will make it easy for you to edit your website.
            Then, choose a website design of your choice from the multiple options given. You can even see a
            preview of how your
            site will look like. Click on "<span className="para-highlight">
              import complete site
            </span>" and click on " <span className="para-highlight">
              import
            </span> ". The entire design will be imported to your
            website.

          </p>

          <p>
            <span className="para-highlight">
              Step 3
            </span> - Edit the content of your website. In order to edit any page on your website, go into that
            page and click on
            " <span className="para-highlight">
              Edit with Elememtor
            </span> ",
          </p>

          <div className="flex">
            <img src={websiteForth} alt="crome-img" />
            <div>
              <p> which should appear at the top of your page.
                Select the text and enter your own text. You can also change the text style, alignment,
                size, colour, spacing and many
                more. In order to change an image, click on <span className="para-highlight">
                  the image
                </span> and you'll have the option of " <span className="para-highlight">
                  choose your own image
                </span> ",
              </p>
            </div>
          </div>
          <p> where you
            can drag and drop any image of your choice from your computer. Once you're done with all the
            changes, click on " <span className="para-highlight">
              update
            </span> ".
            Once you refresh your site you can see all the changes.

          </p>
          <p>
            In order to change the "Header" of your page, you can do it by clicking on the " <span
              className="para-highlight">
              customize
            </span> " option. You can see a blue
            pen icon next to the various tabs on the "Header" can you can click on the pen icon and change it as
            per your choice.
            One you're done with the changes click " <span className="para-highlight">
              Publish
            </span> " and the changes will be made on your website.

          </p>
          <p>
            In order to add a "New Page" to your website, click on "New", and select "Page". Add a new title for
            page and then on
            "Edit with Elememtor", in order to customize your page.
            You can either choose the various elements and drag and drop them on your page, or you can use pre
            made templates.

          </p>
          <p>
            Once you're done with the page click on "Publish" and it'll be done.
          </p>

          <p className="Quotes">
            "A website is like a book, and every click is a new page to explore." <br />
            <span>
              - Dona Mendoza
            </span>
          </p>

          <p className="main-point">
            WHY SHOULD I CARE ABOUT WEBSITES?
          </p>
          <p>
            Websites help you to establish an online presence and boost credibility. They serve as information
            hubs and enable
            communication, and are accessible from everywhere. Websites help to facilitate e-commerce by helping
            one to build a
            brand, support marketing and promotion and are cost effective along with a global reach. A number of
            websites offer free
            educational resources. Media websites help to distribute news and information. Websites can also
            help to collect
            valuable information and data and help to form online forums or communities.
          </p>

          <p className="para-highlight-last">

            I hope you had a fun learning today. See you soon!

          </p>

        </div >

      </div >

      <Footer />

    </div >
  )
}

export default Web
