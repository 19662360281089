import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import BackTopImg from '../../Images/Icons/TopArrow.svg';
import './BackTop.css'; // Import your CSS file for styling

const BackTop = () => {
  const [scrolling, setScrolling] = useState(0);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const scrollThreshold = windowHeight * 0.3;

    if (scrolling > scrollThreshold) {
      document.getElementsByClassName('backToTop')[0].style.display = 'flex';
    } else {
      document.getElementsByClassName('backToTop')[0].style.display = 'none';
    }
  }, [scrolling]);

  window.addEventListener('scroll', () => {
    setScrolling(window.scrollY);
  });

  return (
    <HashLink to="#BackTop" className="backToTop">
      <img src={BackTopImg} alt="" />
    </HashLink>
  );
};

export default BackTop;
