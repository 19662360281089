import { React, useState } from 'react'
import { Modal, useMantineTheme, TextInput, Textarea, Button, NativeSelect } from '@mantine/core';
// import '../CSS/Contact/Contact.css'
import '../CSS/DarkMode/Contact/Contact.css'
import { DateInput } from '@mantine/dates';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactModal = ({ openModal, setOpenModal }) => {
    const URL = process.env.REACT_APP_BACKEND_URL
    const theme = useMantineTheme();
    const timePicker = [
        { value: '', label: 'Select meeting time' },
        { value: '10AM-12PM', label: '10AM-12PM' },
        { value: '12PM-02PM', label: '12PM-02PM' },
        { value: '02PM-04PM', label: '02PM-04PM' },
        { value: '04PM-06PM', label: '04PM-06PM' }
    ];
    const [ModalFormData, SetModalFormData] = useState({
        customerName: "",
        customerPhoneno: "",
        customerEmail: "",
        scheduletime: "",
        selectedDate: "",
        comment: ""
    })

    const ModalDataSaver = (e) => {
        SetModalFormData({ ...ModalFormData, [e.target.name]: e.target.value })
    }

    const DateChanger = (e) => {
        SetModalFormData({ ...ModalFormData, selectedDate: e })
    }

    const ModalFormSubmit = (e) => {
        axios.post(`${URL}/contact/sedulemeeting`, ModalFormData).then((res) => {
            toast.success(res.data)
            setOpenModal(false)
        }).catch(err => {
            toast.error(err.response.data)
        })
    }



    return (
        <>
            <ToastContainer />
            <Modal
                opened={openModal}
                yOffset="7rem"
                onClose={() => setOpenModal(false)}
                style={{ "transition": "0.5s" }}

                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                    top: 0,
                }}
            >
                <form action='post' onSubmit={(e) => e.preventDefault()}>
                    <TextInput type="text" placeholder='Enter your name' h={45} name='customerName' onChange={ModalDataSaver} required />
                    <TextInput type="number" placeholder='Enter your phone no' mt={10} h={45} name='customerPhoneno' onChange={ModalDataSaver} required />
                    <TextInput type="email" placeholder='Enter your Email' mt={10} h={45} name='customerEmail' onChange={ModalDataSaver} required />
                    <Textarea type="text" mt={10} placeholder='Why you contact Martian' name='comment' onChange={ModalDataSaver} required />
                    <NativeSelect
                        data={timePicker}
                        placeholder="Pick all that you like"
                        mt={25}
                        h={50} name='scheduletime' onChange={ModalDataSaver}
                    />

                    <DateInput
                        placeholder="Select your Date "
                        mt={2}
                        top={400}
                        position="top"
                        style={{ "transition": "0.5s" }}
                        onChange={DateChanger}
                        name="selectedDate"
                        required

                    />
                    <Button mt={10} type='button' onClick={ModalFormSubmit}>
                        Submit
                    </Button >
                </form>
            </Modal>
        </>
    )
}

export default ContactModal