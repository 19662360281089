import React, { useState } from 'react'
import BackTopBar from '../Buttons/BackTopBar'
import BackTop from '../Buttons/BackTop'
import Navbar from '../Navbar/Navbar'
import '../CSS/Privacy/Privacy.css'
import Footer from '../Footer/Footer'

const Pricing = () => {
    const [NavSize, SetNavSize] = useState("80%")

    window.addEventListener("load", () => {

        if (window.innerWidth <= 768) {
            SetNavSize("90%")
        } else {
            SetNavSize("80%")

        }
    })
    window.addEventListener("resize", () => {

        if (window.innerWidth <= 768) {
            SetNavSize("90%")
        } else {
            SetNavSize("80%")

        }
    })
    return (
        <>
            <BackTopBar />
            <Navbar width={NavSize} />
            <BackTop />
            <div className='ParentRapper-Privacy'>
                <div style={{ height: '55vh' }}>
                    <h2 className='heading'>Our Pricing</h2>
                    <p className="PrivacyPara">
                        At Martian Corporation, we're excited to offer our Basic Package starting from &#8377; 84999. This package is perfect for startups and small businesses looking to kickstart their technology journey. Here's what you get:
                    </p>
                    <p className="PrivacyPara">
                        <h3>Basic Package: &#8377; 84999</h3>
                    </p>
                    <ul>
                        <li>Essential features to get you started</li>
                        <li>Tailored solutions for your specific needs</li>
                        <li>Dedicated support to guide you every step of the way</li>
                    </ul>
                </div>
                <Footer width="100%" />
            </div>
        </>
    )
}

export default Pricing
