import React from 'react'
import Footer from './Footer'
import bitcoinFive from './Image/bitcoin-five.jpg'
import cryptoImg from './Image/crypto_img.jpg'
import blockchain from './Image/blockchain.png'
import cryptoOneImg from './Image/crypto_one_img.jpg'
import cryptoTwoImg from './Image/crypto_two_img.jpg'
import LstGt from './Image/less_than_icon.png'
import './CSS/style.css'



const Blockchain = () => {
    document.title = 'The key behind bitcoin and cryptocurrency | Martian Blogs'
    return (
        <div className="Martian_Blog_page">
            <div className="previous_page">

                <a href="https://martiancorporation.com/blogs">
                    <img src={LstGt} alt="back-icon" />
                    <h4>
                        Back to Martian Blogs
                    </h4>
                </a>
            </div>




            <header className="header">
                <h2>
                    The key behind bitcoin and cryptocurrency
                </h2>

                <div className="social_media">
                    <a href="http://fb.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://linkedin.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://x.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z">
                            </path>
                        </svg>
                    </a>
                </div>
            </header>



            <div className="main-blog">
                <div className="header_img">
                    <img src={cryptoImg} alt="crypto_image" />
                </div>

                <div className="blog-content">
                    <p className="main-point">
                        A 101 GUIDE TO BLOCKCHAIN – THE KEY BEHIND BITCOIN AND CRYPTOCURRENCY:
                    </p>
                    <p className="main-point">
                        WHAT IS BLOCKCHAIN?
                    </p>

                    <p>
                        Blockchain is like a ledger or a record book where every single block corresponds to a single
                        record.
                        Block = chain of blocks or a chain of records.

                    </p>

                    <p className="main-point">
                        KEY ELEMENTS THAT MAKE UP A BLOCKCHAIN:
                    </p>

                    <p>
                        The first thing which is stored inside a block is data or relevant information. In the case of
                        bitcoin, the transaction
                        information will be showed which will correspond to the sender and receiver information. Different
                        data is stored
                        according to different uses of Blockchain. The second thing which stored inside a block is hash or a
                        unique fingerprint
                        or unique code which is generated for the person storing the information inside the block. “Nonce”
                        or “number used only
                        once”, in <span className="para-highlight">
                            blockchain is a whole number that’s randomly generated when a block is created
                        </span>, which then
                        generates a block
                        header hash. A hash in blockchain is a number permanently attached to the nonce. The third thing
                        which is stored inside
                        a block is the information of the previous hash. So, there will be two hashes in one block. Its own
                        unique hash and the
                        hash from its previous block so as to create a continuous flowing chain. The first block which is
                        created and hence
                        cannot store the information of any previous block is known as Genesis Block.
                    </p>

                    <p>
                        Through the above-mentioned processes, we can track the history of blocks and hence the tracking of
                        information is also
                        easy, every data is unique, it's hard to hack and thus, Blockchain is secure.

                    </p>

                    <p className="main-point">
                        A BRIEF HISTORY OF BLOCKCHAINS:
                    </p>
                    <p>
                        Blockchain came <span className="para-highlight">
                            during the 1990s
                        </span> when research started, but was implemented in 2009. Blockchain
                        history began when

                        <a href="https://en.wikipedia.org/wiki/Satoshi_Nakamoto" target="blank">Satoshi Nakamoto</a>
                        introduced it <span className="para-highlight">
                            in 2008
                        </span> with the release of the Bitcoin whitepaper. The crypto
                        currency boom in the world started from there.
                    </p>
                    <div className="flex">
                        <p>
                            This technology, initially designed for secure and transparent transactions,
                            relies on cryptographic
                            techniques to connect data into blocks and forms an immutable chain. Early users mined Bitcoin to
                            maintain the network,
                            and soon, alternative cryptocurrencies or altcoins emerged. <span className="para-highlight">
                                In 2015
                            </span>, <span className="para-highlight">
                                Ethereum
                            </span> expanded blockchain's potential by
                        </p>
                        <img src={blockchain} alt="blockchain" />
                    </div>

                    <p>

                        introducing smart contracts, enabling decentralized applications. Over time, industries recognized
                        blockchain's benefits
                        for various uses beyond finance, including supply chain management and healthcare. Despite facing
                        challenges like
                        scalability and regulatory scrutiny, blockchain continues to evolve with innovations like <span className="para-highlight">
                            DeFi
                        </span> and
                        <span className="para-highlight">
                            NFTs
                        </span>, expanding its
                        influence across diverse sectors.
                    </p>

                    <p className="main-point">
                        HOW DO BLOCKCHAINS WORK?
                    </p>

                    <p>
                        At its core, a blockchain is like a digital ledger, but it's not a regular spreadsheet. Instead, it
                        organizes
                        information into blocks, and these blocks are linked together in a chain. Each block contains a
                        bunch of data, like
                        transactions or records, and they are connected one after the other. To secure the information in
                        each block and the
                        connection between them, <span className="para-highlight">
                            blockchains use a math technique called cryptography
                        </span>. It's like an
                        unbreakable lock and key.
                        The data inside a block is protected and can only be accessed by those who have the right key.
                    </p>

                    <div className="flex">
                        <img src={cryptoOneImg} alt="" />
                        <p>
                            Blockchains aren't controlled by a single person, company, or government. Instead, they're
                            decentralized, which means
                            lots of people all over the world have copies of the entire blockchain. These people, often referred
                            to as nodes, work
                            together to maintain and verify the data. No single entity has ultimate power.
                        </p>
                    </div>

                    <p>
                        As soon as one
                        changes the data of any
                        particular block, it's hash also automatically changes. Once the hash changes, it means that the
                        next block in the chain
                        (which stores both its own unique hash and the previous hash of the block before it), will reflect
                        irrelevant and
                        invalid information (wrong previous hash code due to change in the previous block), and this process
                        will continue
                        throughout the chain. Wherever in the chain, the data of a block is the changed, the following
                        blocks in the chain will
                        automatically reflect irrelevant and incorrect information. One might think that, "then the solution
                        is simple. I will
                        change the hash of every block in the chain." But it is not so easy and an extremely time-consuming
                        process. In the case
                        of bitcoins, it takes 10mins to change the hash of a single block, now imagine having millions of
                        blocks and the amount
                        of time it'll take to change the hash on every single block in the chain! It'll take you hundreds of
                        years to do so! To
                        make sure everyone agrees on what goes into the blockchain, there's a consensus mechanism. The most
                        common one is called
                        Proof of Work (PoW), which involves solving complex math problems. In simple terms, you have to
                        prove that you've
                        invested say for example, 10mins to bring about a change in a single block in the entire chain.
                    </p>

                    <p>
                        The third security measure is that say there are 100 people inside a particular blockchain network.
                        Then, all those
                        people inside the network, each and every one, will have a copy of that particular Blockchain.
                        Anyone can view the
                        entire blockchain. All the transactions and data are public, but remember, they're protected by that
                        cryptography we
                        talked about earlier. So, you can see what's happening, but you can't tamper with it. In order to
                        change any particular
                        block, you have to send a copy of your block to every single user in your chain, and ask for a vote
                        regarding the
                        legality of your change. If the majority votes for a "no" and states that the change is invalid,
                        then you are bound to
                        not make any changes in your block. This is known as "Consensus Voting", and applies to everything,
                        from adding any new
                        data, to making any change. Only if it passes the consensus vote, you can then make a change.
                    </p>

                    <p className="main-point">
                        TYPES OF BLOCKCHAIN NETWORKS:
                    </p>


                    <p>
                        <span className="para-highlight">
                            Public Blockchains
                        </span>- These are open to anyone and are the most transparent and decentralized. Anyone can join,
                        participate, and view the entire blockchain.
                    </p>

                    <p>
                        E.g., include Bitcoin and Ethereum.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Private Blockchains
                        </span>- These are restricted and typically used within organizations or specific groups. Access and
                        participation are controlled, making them less decentralized but more secure. It is ideal for
                        businesses that want the
                        benefits of blockchain but need more control over who can use it.
                    </p>

                    <div className="flex">
                        <p>
                            <span className="para-highlight">
                                Consortium Blockchains
                            </span>- These are a middle ground between public and private blockchains. A group of organizations
                            or
                            entities collaborates to operate the network. They offer some degree of decentralization while
                            maintaining control among
                            the consortium members. They are often used in industries where multiple parties need to work
                            together, like supply
                            chain management.
                        </p>
                        <img src={cryptoTwoImg} alt="crypto-img" />
                    </div>

                    <p>
                        These types can also be categorized based on their consensus mechanisms, like <span className="para-highlight">
                            Proof of Work (PoW)
                            for Bitcoin
                        </span>  or <span className="para-highlight">
                            Proof
                            of Stake (PoS) for Ethereum 2.0
                        </span> , and whether they are permissionless (open) or permissioned
                        (restricted). The choice of
                        blockchain type depends on the specific use case and requirements of the application.
                    </p>

                    <p className="main-point">
                        APPLICATION OF BLOCKCHAIN:
                    </p>


                    <p>
                        <span className="para-highlight">
                            Cryptocurrencies
                        </span>: This is where it all began with Bitcoin. Cryptocurrencies use blockchain to enable secure
                        and
                        decentralized digital transactions. Bitcoins have helped to eliminate the banks or the middlemen.
                        When compared to
                        banks, the transaction charges using bitcoin is minimal. Like gold is transferred from asset to
                        asset, bitcoin is
                        also
                        transferred bitcoin to bitcoin - which means there will be no currency conversion.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Supply Chain Management
                        </span>: Companies are using blockchain to track products from their origin to the final consumer.
                        This helps ensure the authenticity and quality of goods and reduces fraud.

                    </p>

                    <p>
                        <span className="para-highlight">
                            Smart Contracts
                        </span>: These are self-executing contracts with the terms of the agreement directly written
                        into code. They
                        automatically execute when conditions are met, cutting out the need for intermediaries.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Voting Systems
                        </span>: Some countries are exploring blockchain for secure and transparent voting
                        systems. It can help
                        reduce
                        fraud and increase trust in the electoral process.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Healthcare
                        </span>: Blockchain is also used in HMS or Hospital Management System, where patient records
                        are stored using
                        the
                        help of Blockchain. Doctors are able to access relevant information and the data of patients also
                        remain secure.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Real Estate
                        </span>: Blockchain can streamline property transactions by providing a transparent and
                        tamper-proof record of ownership and history. In the real estate domain, the work of the notary is
                        being done by Blockchain. The buying and
                        selling records of any piece of real estate is stored in a Blockchain and through this process we
                        can also track the
                        history of that particular piece of real estate property.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Tokenization of Assets
                        </span>: Physical assets like real estate, art, or even rare collectibles can be represented as
                        digital tokens on a blockchain, making them easier to buy, sell, and trade.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Decentralized Finance (DeFi)
                        </span>: DeFi platforms use blockchain to create open and permissionless financial services,
                        such as lending, borrowing, and trading, without traditional banks.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Non-Fungible Tokens (NFTs)
                        </span>: NFTs are unique digital assets (like art or collectibles) that are bought and sold on
                        blockchains, proving ownership and authenticity.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Cross-Border Payments
                        </span>: Blockchain technology can make international money transfers faster and more
                        cost-effective
                        by bypassing traditional banking systems.
                    </p>

                    <p>
                        <span className=" para-highlight">
                            Gaming
                        </span>:
                        Some video games are incorporating blockchain to create unique in-game assets that players can buy,
                        sell,
                        and trade.
                    </p>

                    <p>
                        <span className=" para-highlight">
                            Identity Verification
                        </span>: Blockchain can provide a secure and verifiable way to manage digital identities, reducing
                        identity theft and fraud.
                    </p>


                    <p className="main-point">
                        VARIOUS BLOCKCHAIN PLATFORMS:
                    </p>

                    <div className="flex">
                        <img src={bitcoinFive} alt="bitcoin" />
                        <div>
                            <p>
                                <span className="para-highlight">
                                    Ethereum
                                </span>: Ethereum is one of the most well-known blockchain platforms, famous for its smart contract capabilities.
                                It's used for creating decentralized applications (DApps) and has a large developer community.

                            </p>

                            <p>
                                <span className="para-highlight">
                                    Bitcoin
                                </span>: The original blockchain platform, Bitcoin, is primarily designed for peer-to-peer digital currency
                                transactions. It
                                introduced the concept of blockchain technology.

                            </p>
                        </div>

                    </div>

                    <p>
                        <span className="para-highlight">
                            Binance Smart Chain (BSC)
                        </span>:BSC is a blockchain platform created by Binance, known for its high-speed and low-cost transactions. It's commonly used
                        for decentralized finance (DeFi) projects.

                    </p>

                    <p>
                        <span className="para-highlight">
                            Polkadot
                        </span>:Polkadot aims to connect multiple blockchains into a single network, allowing them to communicate and share information.
                        It focuses on interoperability and scalability.

                    </p>

                    <p>
                        <span className="para-highlight">
                            Cardano
                        </span>:Cardano is a blockchain platform known for its focus on security and sustainability. It uses a PoS consensus mechanism
                        and is designed for smart contracts and DApps.

                    </p>

                    <p>
                        <span className="para-highlight">
                            Tezos
                        </span>:Tezos is a self-amending blockchain platform, which means it can upgrade itself through on-chain governance. It's
                        designed for smart contracts and tokenization.

                    </p>

                    <p>
                        <span className="para-highlight">
                            Avalanche
                        </span>:Avalanche is known for its high-speed and highly scalable blockchain platform. It offers a unique consensus mechanism
                        called Avalanche consensus.

                    </p>

                    <p>
                        <span className="para-highlight">
                            Chainlink
                        </span>: Chainlink is a decentralized oracle network that connects smart contracts to real-world data sources and
                        APIs, enhancing the capabilities of blockchain applications.

                    </p>

                    <p>
                        <span className="para-highlight">
                            Stellar
                        </span>: Stellar is designed for cross-border payments and asset tokenization. It focuses on facilitating fast and low-cost
                        transactions.
                    </p>

                    <p>
                        <span className="para-highlight">
                            EOS
                        </span>: EOS is known for its scalability and speed. It's used for building decentralized applications and aims to provide a
                        user-friendly experience.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Tron
                        </span>:Tron focuses on creating a decentralized internet and offers a platform for DApps and content sharing.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Hyperledger
                        </span>:Hyperledger Fabric is an open-source blockchain platform tailored for enterprise use. It offers permissioned blockchain
                        networks with high levels of privacy and control.
                    </p>


                    <p className="Quotes">
                        "Blockchain is a new way of thinking about trust." <br /> <span>
                            - Gavin Wood, co-founder of Ethereum
                        </span>
                    </p>

                    <p className="main-point">
                        BLOCKCHAIN AND BUSINESS:
                    </p>

                    <p>
                        Blockchain technology is revolutionizing business operations through its implementation in various sectors. In supply
                        chain management, it ensures transparency and traceability, reducing fraud and verifying product authenticity. Smart
                        contracts, self-executing agreements coded on blockchain, automate processes and reduce the need for intermediaries in
                        numerous transactions. Digital identity verification is enhanced with secure, tamper-proof identity management systems.
                        Cross-border payments are expedited with blockchain, cutting costs and settlement times. Asset tokenization digitizes
                        physical assets, improving accessibility and divisibility. <span className="para-highlight">
                            Decentralized Finance (DeFi) platforms offer peer-to-peer
                            financial services
                        </span>, and blockchain facilitates secure record-keeping, intellectual property protection, and efficient
                        customer loyalty programs. It transforms industries like energy, food safety, and insurance with transparent, efficient
                        solutions. Blockchain's adoption continues to grow, enhancing security, transparency, and efficiency in business
                        processes across diverse domains.
                    </p>


                    <p className="para-highlight-last">
                        Hope you had fun learning today! See you soon!
                    </p>


                </div>

            </div>

            <Footer />

        </div>
    )
}

export default Blockchain
