import React from 'react'
import './CSS/style.css'
import Saas1Img from './Image/Saas1.jpg'
import SaaS3Img from './Image/SaaS3.jpg'
import HeaderImg from './Image/Header-img.jpg'
import IaaSImg from './Image/IaaS.jpg'
import PaaSImg from './Image/PaaS.jpg'
import LstGt from './Image/less_than_icon.png'
import Footer from './Footer'

const Saas = () => {
    document.title = 'LET’S Know SaaS, PaaS and IaaS | Martain Blogs'
    return (
        <div className="Martian_Blog_page">

            <div className="previous_page">

                <a href="https://martiancorporation.com/blogs">
                    <img src={LstGt} alt="less_than" />
                    <h4>
                        Back to Martian Blogs
                    </h4>
                </a>
            </div>

            <header className="header">
                <h2>
                    LET’S Know SaaS, PaaS and IaaS
                </h2>

                <div className="social_media">
                    <a href="http://fb.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://linkedin.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://x.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z">
                            </path>
                        </svg>
                    </a>
                </div>
            </header>

            <div className="main-blog">
                <div className="header_img">
                    <img src={HeaderImg} alt="cloud" />
                </div>

                <div className="blog-content">
                    <p className="main-point">
                        NO CLUE ABOUT SaaS?
                    </p>
                    <p>
                        BEFORE WE BEGIN, LET’S KNOW THE DIFFERENCE BETWEEN SaaS, PaaS and IaaS:
                    </p>
                    <p>
                        SaaS stands for <span className="para-highlight">
                            Software as a Service
                        </span>. SaaS is one of the three main categories of cloud computing along with IaaS -
                        <span className="para-highlight">
                            Infrastructure as a Service
                        </span> and PaaS - <span className="para-highlight">
                            Platform as a Service
                        </span>. All of them serve as Cloud Computing Delivery Models.
                    </p>

                    <p>SaaS is the most popular and commonly used out of the three. The best part about SaaS is that you do
                        not need to be a
                        computer nerd, or an IT expert in order to use it. SaaS is a method of <span className="para-highlight">
                            delivering software
                        </span> and <span className="para-highlight">
                            applications
                        </span> over the
                        internet via a <span className="para-highlight">
                            subscription model
                        </span>. This is a game changer compared to our traditional software models, used prior to the
                        emergence of cloud computing where users would have to manage, install and upgrade software
                        themselves on local servers
                        or computers.
                    </p>
                    <p>E.g., Gmail, Netflix, Hulu, Microsoft Office, Spotify, Adobe Creative Cloud.</p>

                    <div className="flex">
                        <p>
                            <span className="para-highlight">
                                SaaS
                            </span> is used in a wide variety of business operations such as communication and collaboration,
                            customer relationship
                            management, billing, sales management, human resources management, financial management,
                            enterprise resource
                            planning.
                        </p>
                        <img src={SaaS3Img} alt="SaaS" />
                    </div>

                    <div className="flex">
                        <img src={PaaSImg} alt="PaaS" />
                        <p>
                            <span className="para-highlight">
                                PaaS
                            </span> stands for Platform as a Service is like a <span className="para-highlight">
                                pre-prepared canvas for building software apps
                            </span>. It provides the
                            tools
                            and foundation, so you can focus on creating your apps without worrying about the technical
                            details or
                            infrastructure.
                            It's all about making app development simpler and more efficient.
                        </p>
                    </div>



                    <div className="flex">
                        <p>
                            <span className="para-highlight">
                                IaaS
                            </span> is short for Infrastructure as a Service. IaaS is like <span className="para-highlight">
                                renting virtual computer hardware from the internet
                            </span>. You
                            use
                            your own software on this rented hardware, while the provider takes care of all the technical
                            maintenance,
                        </p>

                        <img src={IaaSImg} alt="IaaS" />
                    </div>
                    <p>like
                        fixing
                        and upgrading the actual machines. The vendor provides <span className="para-highlight">servers</span>, <span
                            className="para-highlight">storage</span>, <span className="para-highlight">networking</span>, <span
                                className="para-highlight">virtualization</span>, <span className="para-highlight">Middleware</span>,
                        Operating System and the Runtime.</p>
                    <p>
                        SaaS has the highest level of
                        <span className="para-highlight">
                            abstraction
                        </span>- ease of use and decreasing complexity
                    </p>
                    <p>
                        (Abstraction - <span className="para-highlight">
                            The amount of complexity by which a system is viewed or programmed
                        </span>. The higher the level, the less
                        detail. The lower the level, the more detail. The highest level of abstraction is the entire system.
                        The next level
                        would be a handful of components, and so on, while the lowest level could be millions of objects.)
                    </p>
                    <p>
                        The cloud service provider will be providing and maintaining all the essential requirements like
                        Application, Data,
                        Runtime, OS, Virtualization, Hardware. There are multiple users all accessing the same pool of
                        resources within the
                        stack.
                    </p>
                    <p className="main-point">
                        LET’S LOOK AT A LITTLE HISTORY:
                    </p>
                    <p>
                        <span className="para-highlight">
                            In the 1960s
                        </span>, mainframe computers were linked to dumb terminals, and they shared the mainframe's
                        software in a setup
                        known as time-sharing. <span className="para-highlight">
                            Come the 1980s
                        </span>, as computer costs dropped, many businesses started their own
                        localized version of
                        time-sharing, called a local-area network (LAN). Here's the catch: the business, not the tech
                        provider, had to handle
                        the hardware and network.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Fast forward to the 1990s
                        </span>, and the internet takes the stage. Providers started hosting software and
                        providing it out to
                        customers via the web. This precursor to SaaS went by the name "<span className="para-highlight">
                            application service provider
                        </span>" or <span className="para-highlight">
                            ASP model
                        </span>. But it had
                        its problems. Each customer had to set up their own software on users' computers, which was a
                        hassle. Configuring it
                        took time and money. Plus, ASP solutions struggled to efficiently gather and crunch data.
                    </p>

                    <p>
                        Now, let's zoom in on the late 1990s, where the term <span className="para-highlight">
                            "SaaS" first came
                        </span> into play. This new approach
                        was a game-changer.
                        One instance of the app could serve many users and customers thanks to its " <span className="para-highlight">
                            multi-tenant
                            architecture
                        </span>." No more local
                        software installations, and it offered a neat way to gather, centralize, and make sense of valuable
                        app data.
                    </p>

                    <p>
                        While the delivery style has stuck around since the early 2000s, SaaS has come a long way. It
                        shifted from those early,
                        isolated solutions to modern SaaS suites. These fancy suites bring super visibility to businesses
                        and can even mix
                        things up with embedded tech like AI, machine learning, chatbots, digital assistants, IoT,
                        blockchain, augmented
                        reality, and virtual reality.
                    </p>


                    <p className="main-point">
                        FEATURES OF SaaS:
                    </p>
                    <p>
                        <span className="para-highlight">
                            Accessibility
                        </span>: You can use SaaS from anywhere with the internet. It's like carrying your tools with
                        you everywhere.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Automatic Updates
                        </span>: SaaS keeps itself fresh. You don't have to worry about downloading and installing
                        updates; it's done
                        for you.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Cost-Efficiency
                        </span>: It's often cheaper than buying and managing your software and hardware. You pay for
                        what you use, like
                        paying for water or electricity.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Scalability
                        </span>: SaaS can grow with you. If you need more power or features, you can usually get them
                        without a fuss.
                    </p>
                    <p>
                        <span className="para-highlight">
                            No Installation
                        </span>: Forget about the headache of installing software. Just log in, and you're ready to
                        go.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Data Security
                        </span>: SaaS providers usually have robust security measures in place to keep your data safe
                    </p>

                    <p>
                        <span className="para-highlight">
                            Accessibility from Any Device
                        </span>: You can use SaaS on your computer, tablet, or even your smartphone.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Multi-User Support
                        </span>: Many people can use SaaS at once. It's like sharing a tool with your friends.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Pay-as-You-Go
                        </span>: You pay for SaaS on a subscription basis, like Netflix. No big upfront costs.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Support and Maintenance
                        </span>: SaaS providers handle the tech stuff. If something breaks, they fix it.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Collaboration
                        </span>: SaaS often comes with collaboration features, making it easy to work with others,
                        like sharing documents
                        or projects.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Backup and Recovery
                        </span>: Your data is often backed up, so if something goes wrong, you don't lose your
                        work.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Customization
                        </span>: Some SaaS apps allow you to tweak things to fit your needs better.
                    </p>
                    <p>
                        <span className="para-highlight">
                            User-Friendly
                        </span>: SaaS is designed to be easy to use, even if you're not an IT genuis.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Free Trials
                        </span>: Many SaaS services offer free trials, so you can test them out before committing.
                    </p>


                    <p className="main-point">
                        HOW DOES SaaS WORK?
                    </p>


                    <p>
                        In SaaS, there's a single version of the software, and all customers use it – multiple users at the
                        same time. If
                        there’s any problem in SaaS, engineers can make updates and fix bugs faster for all the users.
                        Multiple users mean
                        multiple data. The <span className="para-highlight">
                            customer data in SaaS is kept separate in the database
                        </span> and in order to access it
                        you have to log in
                        without your <span className="para-highlight">
                            unique username and password
                        </span>. The software knows which data to show you. In SaaS, you
                        can see your own data
                        and settings, even though it's being used my multiple users at the same time.
                    </p>

                    <p className="main-point">
                        BENEFITS OF USING SaaS:
                    </p>

                    <div className="flex">
                        <p>
                            <span className="para-highlight">
                                Boost Lead Management
                            </span>: SaaS helps you keep a close eye on potential customers during the sales
                            process. It's like
                            having a detective who helps you identify and track leads more effectively.
                        </p>
                        <img src={Saas1Img} alt="SaaS-img" />
                    </div>


                    <p>
                        <span className="para-highlight">
                            Enhance Sales-Marketing Teamwork
                        </span>: SaaS makes it easier for your sales and marketing teams to work
                        together. They can
                        share important details about prospects and customers. It's like having a smooth communication
                        channel.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Streamline Marketing
                        </span>: With SaaS, you can make your digital marketing campaigns run like clockwork.
                        It's like having a
                        well-oiled marketing machine.
                    </p>
                    <p>
                        <span className="para-highlight">
                            Manage Data Better
                        </span>: SaaS helps you handle your data more efficiently. Think of it as a filing system
                        for your digital
                        information.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Organize Contacts
                        </span>: You can use SaaS to keep all your customer, prospect, and sales lead information
                        neat and tidy. It's
                        like having a digital address book that's organized.
                    </p>

                    <p className="main-point">
                        Conclusion:
                    </p>
                    <p>
                        To sum it up, So, in simple terms, SaaS features help your business by making lead management,
                        teamwork, marketing, data handling, and
                        contact management smoother and more efficient. Hope you had a fun learning time today. See you
                        soon!
                    </p>
                    <p>
                        <span className="para-highlight-last">
                            Do you Ever use SaaS,PaaS or Iaas ?.
                        </span>
                    </p>

                </div>

            </div>

            <Footer />
        </div >
    )
}

export default Saas
