import React from 'react'
import './CSS/style.css'
import LstGt from './Image/less_than_icon.png'
import MlImg from './Image/ML.jpg'
import MLThree from './Image/ML-three.png'
import MLTwo from './Image/ML-two.avif'
import Footer from './Footer'

const Ml = () => {
    return (
        <div className="Martian_Blog_page">



            <div className="previous_page">

                <a href="https://martiancorporation.com/blogs">
                    <img src={LstGt} alt="back-icon" />
                    <h4>
                        Back to Martian Blogs
                    </h4>
                </a>
            </div>




            <header className="header">
                <h2>
                    LET’S TALK ABOUT Machine Learning
                </h2>

                <div classNameName="social_media">
                    <a href="http://fb.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://linkedin.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://x.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z">
                            </path>
                        </svg>
                    </a>
                </div>
            </header>



            <div className="main-blog">
                <div className="header_img">
                    <img src={MlImg} alt="crypto_image" />
                </div>

                <div className="blog-content">
                    <p className="main-point">
                        What is ML?
                    </p>

                    <p>
                        ML is an abbreviation for machine learning, which is a type of artificial intelligence (AI) that
                        allows software
                        applications to become more accurate in predicting outcomes without being explicitly programmed to
                        do so.

                    </p>

                    <p className="main-point">
                        HISTORY:
                    </p>

                    <p>
                        The history of machine learning can be traced back to the early days of artificial intelligence
                        research in the 1950s.
                        One of the earliest examples of machine learning was a program developed by Arthur Samuel in 1959
                        that could learn to
                        play checkers at a high level.
                    </p>

                    <p>
                        <span className="para-highlight">
                            In the 1960s and 1970s
                        </span>, machine learning research focused on developing algorithms that could learn
                        from data to make
                        predictions or decisions. Some of the most important algorithms developed during this period include
                        linear regression,
                        decision trees, and support vector machines.
                    </p>
                    <p>
                        <span className="para-highlight">
                            In the 1980s and 1990s
                        </span>, machine learning research began to focus on developing more sophisticated
                        algorithms, such as
                        <span className="para-highlight">
                            neural networks
                        </span> and <span className="para-highlight">deep learning</span>. These algorithms are able to learn from large amounts of data and
                        perform complex
                        tasks, such as image recognition and natural language processing.
                    </p>
                    <p>
                        In the 2000s and 2010s, machine learning has become increasingly popular and widely used. This is
                        due in part to the
                        availability of large amounts of data and powerful computers that can train machine learning models.
                    </p>

                    <p className="main-point">
                        METHOD OF MACHINE LEARNING:

                    </p>

                    <ul>
                        <li>Supervised Machine Learning.</li>
                        <li> Unsupervised Machine Learning.</li>
                        <li>Reinforcement Learning.</li>

                    </ul>

                    <p className="main-point">
                        Explain method of Machine Learning
                    </p>
                    <p>
                        <span className="para-highlight">supervised machine learning:</span> Supervised learning algorithms are
                        trained on a set of labeled data, where each data point
                        has a known input and output. The algorithm learns to predict the output for new input data points
                        based on the patterns
                        it has learned from the training data.
                    </p>
                    <p>
                        <span className="para-highlight">Unsupervised learning:</span> Unsupervised learning algorithms are
                        trained on a set of unlabeled data, where the data points do
                        not have any known outputs. The algorithm learns to identify patterns and relationships in the data
                        without being
                        explicitly told what to look for.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Reinforcement learning:
                        </span> Reinforcement learning algorithms learn to perform a task by trial and error.
                    </p>



                    <p className="main-point">
                        APPLICATION OF ML:
                    </p>

                    <div className="flex">
                        <ul>
                            <li> Image Recognition.</li>
                            <li> Speech Recognition.</li>
                            <li> Recommender Systems.</li>
                            <li> Fraud Detection.</li>
                            <li> Self-Driving Cars.</li>
                            <li> Medical Diagnosis.</li>
                            <li> Stock Market Trading.</li>
                            <li> Virtual Try On.</li>
                            <li> Natural Language Processing.</li>
                        </ul>
                        <img src={MLTwo} alt="Machine_Learning_img" />
                    </div>



                    <p className="main-point">
                        WHY WE USE ML?
                    </p>
                    <p>
                        ML algorithms use to be trained on histrocial data to learn patterns and relationships. This allows
                        them to make
                        predictions about future events.
                    </p>

                    <p>
                        ML algorithms use to automate repetitive tasks, such as <span className="para-highlight">
                            sorting data
                        </span> or <span className="para-highlight">
                            classNameifying images
                        </span>.
                    </p>
                    <p>
                        ML algorithms help us to make better decisions by providing us with insights into our data that we
                        would not be able
                        to see on our own.
                    </p>

                    <p className="main-point">
                        HOW PEOPLE BENEFIT OF ML:
                    </p>

                    <p>
                        <span className="para-highlight">Improved decision-making:</span> ML algorithms can helped us to identify customer segments or to predict the impact of a new
                        product launch.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Increased efficiency:
                        </span> ML automate repetitive tasks, such as sorting data or classNameifying images.
                    </p>

                    <div className="flex">
                        <img src={MLThree} alt="ML-img" />
                        <p>
                            <span className="para-highlight">
                                Personalized experiences:
                            </span> ML algorithms can be used to recommend products, movies, music, and other content to users
                            based on their past preferences.
                        </p>
                    </div>

                    <p>
                        <span className="para-highlight">
                            Improved safety:
                        </span> ML algorithms helped us to detect fraud, identify safety hazards, and predict natural disasters.
                    </p>


                    <p>
                        <span className="para-highlight">
                            New products and services:
                        </span> ML is used to power self-driving cars, virtual assistants, and medical diagnostic tools.
                    </p>

                    <p>
                        <span className="para-highlight">
                            E-commerce:
                        </span> ML algorithms are used by e-commerce companies to recommend products to customers based on their past
                        purchase history and browsing behavior. This helps customers find the products they are most likely to be interested in
                        and makes it easier for them to shop.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Finance:
                        </span> ML algorithms are used by financial institutions to detect fraud and assess risk. This helps to protect
                        consumers' money and makes the financial system more stable.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Healthcare:
                        </span> ML algorithms are used to diagnose diseases, develop treatments, and predict patient outcomes. This is
                        helping to improve the quality and efficiency of healthcare delivery.
                    </p>

                    <p>
                        <span className="para-highlight">
                            Transportation:
                        </span> ML algorithms are used to develop self-driving cars and other intelligent transportation systems. This
                        is helping to make transportation safer and more efficient.
                    </p>

                    <p className="Quotes">
                        "We are entering a new world. A world where machines are learning to think for themselves. A world where AI is changing
                        the way we live, work, and play."  <br /> <span>
                            - Sundar Pichai
                        </span>
                    </p>
                    <p className="main-point">
                        CONCLUSION
                    </p>

                    <p>
                        Machine learning is a rapidly developing field with the potential to revolutionize many industries and aspects of our
                        lives. ML algorithms can learn from data to make predictions, automate tasks, improve decision-making, and personalize
                        experiences. ML is already being used in a variety of industries, including healthcare, finance, transportation, and
                        e-commerce. As the field of ML continues to develop, we can expect to see even more innovative and groundbreaking
                        applications of ML in the years to come.
                    </p>
                    <p>
                        machine learning is a powerful tool with the potential to make a significant positive impact on the world.
                    </p>







                    <p className="para-highlight-last">
                        Did You Benefit By ML?
                    </p>


                </div>

            </div>

            <Footer />

        </div>
    )
}

export default Ml
