import React from 'react'
import './CSS/style.css'

const Footer = () => {
    return (
        <div>
            <div className="explore-section">

                <a href="https://martiancorporation.com/blogs">
                    <h1>
                        Explore More Blogs
                    </h1>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="18" viewBox="0 0 31 18">
                        <path d="M23.6309 10H6.75586C6.23086 10 5.81836 9.56 5.81836 9C5.81836 8.44 6.23086 8 6.75586 8H23.6309C24.1559 8 24.5684 8.44 24.5684 9C24.5684 9.56 24.1559 10 23.6309 10Z" />
                        <path d="M18.9434 17C18.8204 17.0016 18.6985 16.9757 18.5855 16.9241C18.4725 16.8724 18.3708 16.7961 18.2871 16.7C17.9121 16.3 17.9121 15.68 18.2871 15.28L24.1934 8.97999L18.2871 2.67999C17.9121 2.27999 17.9121 1.65999 18.2871 1.25999C18.6621 0.859991 19.2434 0.859991 19.6184 1.25999L26.1809 8.25999C26.5559 8.65999 26.5559 9.27999 26.1809 9.67999L19.6184 16.68C19.4309 16.88 19.1871 16.98 18.9621 16.98L18.9434 17Z" />
                    </svg>
                </a>

            </div>
            <div className="stay-update">
                <h2>
                    Never miss a story
                </h2>

                <h3>
                    Stay updated about MartianCorp news as it happens
                </h3>

                <div className="button">
                    <input type="email" name="email" id="" placeholder="Email address" className="input-field" />
                    <button>Subscribe</button>
                </div>
            </div>
        </div>
    )
}

export default Footer
