import React from 'react'
import AIimg from './Image/AI.jpg'
import AI2img from './Image/AI2.jpg'
import AI3img from './Image/AI3.jpg'
import AI4img from './Image/AI4.jpg'
import chatGPTimg from './Image/chatGPT.jpg'
import lessThanIconimg from './Image/less_than_icon.png'
import Footer from './Footer'
import './CSS/style.css'

const Ai = () => {
    document.title = 'WHAT IS AI? | Martian Blogs'
    return (
        <div className="Martian_Blog_page">


            <div className="previous_page">

                <a href="https://martiancorporation.com/blogs">
                    <img src={lessThanIconimg} alt="" />
                    <h4>
                        Back to Martian Blogs
                    </h4>
                </a>
            </div>


            <header className="header">
                <h2>
                    WHAT IS AI?
                </h2>

                <div className="social_media">
                    <a href="https://www.facebook.com/">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
                            </path>
                        </svg>
                    </a>
                    <a href="https://linkedin.com/">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z">
                            </path>
                        </svg>
                    </a>
                    <a href="https://x.com/">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z">
                            </path>
                        </svg>
                    </a>
                </div>
            </header>

            <div className="main-blog">
                <div className="header_img">
                    <img src={AI2img} alt="" />
                </div>

                <div className="blog-content">
                    <p className="main-point">
                        WHAT IS AI?
                    </p>

                    <p>
                        Artificial intelligence (AI) is the <span className="para-highlight">
                            ability of a machine
                        </span> to <span className="para-highlight">
                            perform tasks
                        </span> that typically require <span className="para-highlight">
                            human intelligence
                        </span>,
                        such as reasoning, learning, and problem-solving. AI systems are able to learn from data and experience, and they can
                        adapt to <span className="para-highlight">
                            new situations
                        </span>.

                    </p>

                    <p className="main-point">
                        HISTORY OF AI:
                    </p>

                    <p style={{width:"100%"}}>
                        1950: Alan Turing published a paper called " <span className="para-highlight">
                            Computing Machinery and Intelligence
                        </span>," in which he proposed a test of
                        machine intelligence that is now known as the Turing test.
                        <p>
                            1956: The Dartmouth Summer Research Project on Artificial Intelligence is held, which is widely considered to be the
                            birth of AI as a modern academic discipline.
                        </p>
                        <p>
                            1960s: AI researchers develop expert systems.
                        </p>
                        <p>
                            1970s: AI researchers developed <span className="para-highlight">
                                natural language processing
                            </span> (NLP) systems.
                        </p>
                        <p>
                            1980s: AI researchers develop machine learning systems.
                        </p>
                        <p>
                            2000s: AI researchers develop <span className="para-highlight">
                                deep learning systems
                            </span> .
                        </p>
                        <p>
                            2010s: AI begins to be used in a wide range of applications.
                        </p>

                        <p>
                            AI is important to stay informed about the  <span className="para-highlight">
                                latest developments in AI
                            </span> and to be thoughtful about how we use this powerful
                            technology.
                        </p>


                        <p className="main-point">
                            TYPES OF AI:
                        </p>
                        <div className="flex">
                            <div>
                                <ul>
                                    <li>

                                        Narrow AI.
                                    </li>
                                    <li>

                                        General AI .
                                    </li>
                                    <li>
                                        Super AI.
                                    </li>
                                    <li>

                                        Reactive Machines.
                                    </li>
                                    <li>

                                        Limited Memory .
                                    </li>

                                    <li>

                                        Theory of Mind .
                                    </li>
                                    <li>
                                        Self-Awareness.
                                    </li>
                                </ul>
                            </div>
                            <img src={AI4img} alt="Robot_AI" />

                        </div>




                        <p className="main-point">
                            Let's Explain Of Types :
                        </p>

                        <p>
                            <span className="para-highlight">
                                Narrow AI
                            </span> : Narrow AI is a type of AI that is designed to perform a specific task or set of tasks. For example, a
                            narrow AI system might be designed to play chess, translate languages, or identify objects in images.
                        </p>

                        <p>
                            <span className="para-highlight">
                                General AI
                            </span> : General AI is a type of AI that is designed to be as intelligent as a human being. It would be able to
                            learn and perform any task that a human can. General AI does not yet exist, but it is the goal of many AI researchers.
                        </p>

                        <div className="flex">
                            <img src={AI3img} alt="ai-pic" />
                            <div>
                                <p>
                                    <span className="para-highlight">
                                        Super AI
                                    </span> : Super AI, also known as Artificial General Intelligence (AGI), is a hypothetical type of AI that would be
                                    as
                                    intelligent as or more intelligent than a human being. AGI would be able to learn and perform any task that a human
                                    can,
                                    and it would be able to do so independently and without human input.
                                </p>

                                <p>
                                    <span className="para-highlight">
                                        Reactive Machines
                                    </span> : these are the simplest type of AI. They are able to respond to stimuli from their environment,
                                    butthey do not have any

                                </p>
                            </div>

                        </div>

                        <p>
                            memory or ability to learn. Reactive machines are commonly used in video games and other
                            applications where the AI needs to be able to react quickly to changes in its environment.
                        </p>

                        <p>
                            <span className="para-highlight">
                                Limited Memory
                            </span>: Limited memory AI systems are able to store and use information from the past to make better decisions
                            in the present. However, their memory is limited, and they are not able to learn and adapt to new situations as quickly
                            as more advanced AI systems. Limited memory AI systems are commonly used in applications such as fraud detection and
                            product recommendation.
                        </p>

                        <p>
                            <span className="para-highlight">
                                Theory of Mind
                            </span>: The theory of mind is the ability to understand the thoughts, feelings, and beliefs of others. AI
                            systems with theory of mind would be able to better understand and interact with humans, as well as with other AI
                            systems. Theory of mind is a critical component of human intelligence, and it is one of the most challenging areas of AI
                            research.
                        </p>
                        <p>
                            <span className="para-highlight">
                                Self-awareness
                            </span>: Self-awareness is the ability to understand oneself as a distinct individual with its own thoughts,
                            feelings, and experiences. Self-awareness is considered to be a hallmark of human intelligence, it is not clear whether
                            or not AI systems will ever be able to achieve it. And it is not clear whether or not AI systems will ever be able to
                            achieve it.
                        </p>




                        <p className="main-point">
                            APPLICATION OF AI:
                        </p>
                        <div className="flex">
                            <div>
                                <ul>
                                    <li>
                                        Healthcare.
                                    </li>
                                    <li>
                                        Finance.
                                    </li>
                                    <li>
                                        Autonomous Vehicles.
                                    </li>
                                    <li>
                                        Natural Language Processing.
                                    </li>
                                    <li>
                                        E-commerce.
                                    </li>
                                    <li>
                                        Manufacturing.
                                    </li>
                                    <li>
                                        Energy and Environments.
                                    </li>
                                    <li>
                                        Education.
                                    </li>
                                    <li>
                                        Entertainments.
                                    </li>
                                    <li>
                                        Security.
                                    </li>
                                </ul>
                            </div>

                            <img src={AIimg} alt="" />
                        </div>


                        <p className="main-point">
                            WHY WE USE AI?
                        </p>
                        <p>
                            AI helps us to develop <span className="para-highlight">new
                                drugs
                            </span>,
                            <span className="para-highlight">design self-driving
                                cars
                            </span>,
                            and create new algorithms for machine learning.
                        </p>
                        <p>
                            AI can be used to help doctors diagnose diseases, predict financial markets, and personalize marketing campaigns.
                        </p>
                        <p>
                            AI can be used to automate tasks such as customer service, data entry, and financial analysis.
                        </p>
                        <p>
                            AI can be required to develop new <span className="para-highlight">
                                medical treatments
                            </span>, create more efficient transportation systems, and personalize
                            education and entertainment.
                        </p>


                        <div className="middle-point">
                            <img src={chatGPTimg} alt="chatGPT" />
                            <div>
                                <h2>AI  Principles</h2>
                                <p>We think artificial intelligence has the potential to revolutionize business, and we've created these rules to assist
                                    our clients and employees in realizing that promise in a moral, legitimate, and sustainable manner.</p>
                            </div>
                        </div>


                        <p className="main-point">
                            Drawbacks of Artificial Intelligence:
                        </p>

                        <p>
                            As AI systems become more sophisticated , they will be able to<span className="para-highlight">
                                automate more and more tasks
                            </span>   that are currently done by
                            humans.
                        </p>
                        <p>
                            An AI system that is used to predict recidivism rates could be biased against certain racial groups if the data used
                            to train it is biased.
                        </p>
                        <p>
                            A <span className="para-highlight">
                                hacker
                            </span> could potentially exploit an AI system to cause a blackout or a <span className="para-highlight">
                                train derailment
                            </span>.
                        </p>
                        <p>
                            AI could lead to a new arms race and potentially make <span className="para-highlight">
                                war more deadly and destructive
                            </span> .
                        </p>
                        <p>
                            Some experts worry that AI systems could become so powerful that they could escape human control. This could lead to a
                            scenario where AI systems make their own decisions, which could be <span className="para-highlight">
                                harmful to humans
                            </span> .
                        </p>

                        <p className="Quotes">
                            "Artificial intelligence is here to stay. Whether we like it or not, it will become an integral part of our daily
                            lives." <br /> <span>
                                - Sundar Pichai
                            </span>
                        </p>

                        <p className="main-point">
                            CONCLUSION:

                        </p>

                        <p>
                            AI is already being used in a wide range of applications, including
                            <span className="para-highlight">
                                healthcare
                            </span>,
                            <span className="para-highlight">
                                transportation
                            </span>, and <span className="para-highlight">
                                customer
                                service
                            </span>.AI has the potential to benefit people in many ways, including improved healthcare,
                            increased productivity and
                            efficiency, enhanced creativity and innovation, greater access to information and education, and
                            improved quality of
                            life.
                            We need to make sure that AI systems are aligned with <span className="para-highlight">
                                human values
                            </span> and that they do not harm individuals or society.
                            It is already being used in a wide range of applications, and it has the potential to benefit people
                            in many ways.
                            However, it is important to use AI responsibly and ethically, and to make sure that AI systems are
                            aligned with human
                            values.

                        </p>



                        <p>
                            <span className="para-highlight-last">
                                Do you think that AI will take over the world after a few years?
                            </span>
                        </p>
                    </p>
                </div>

            </div>

            <Footer />

        </div>
    )
}

export default Ai
