import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Components/Home/Home'
import Contact from './Components/Contact/Contact'
import Career from './Components/Career/Career'
import Blogs from './Components/Blogs/Blogs'
import WhoWeAre from './Components/WhoWeAre/WhoWeAre'
import Services from './Components/Services/Services'
import SearchJobs from './Components/Career/SearchJobs';
import Ai from './Components/BlogContent/Ai'
import Web from './Components/BlogContent/Web'
import Iot from './Components/BlogContent/Iot'
import Ml from './Components/BlogContent/Ml'
import Mvp from './Components/BlogContent/Mvp'
import Cybersecurity from './Components/BlogContent/CyberSecurity'
import Cloudcomputing from './Components/BlogContent/Cp'
import Saas from './Components/BlogContent/Saas'
import Techadp from './Components/BlogContent/TechAdp'
import Code from './Components/BlogContent/Code'
import Blockchain from './Components/BlogContent/Blockchain'
import Privacy from './Components/Privacy/Privacy';
import Refund from './Components/Privacy/Refund';
import Pricing from './Components/Privacy/Pricing';
import Phonepe from './Components/payment/PhonePe';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/career' element={<Career />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/about' element={<WhoWeAre />} />
          <Route path='/services' element={<Services />} />
          <Route path='/jobsearch' element={<SearchJobs />} />
          {/*===========----- privacy Routes ----=========*/}
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/refund' element={<Refund />} />
          <Route path='/pricing' element={<Pricing />} />
          {/*============-----Payment Intregration-------============= */}
          <Route path='/payment' element={<Phonepe />} />
          {/*=======----Blogs Routes ----===========*/}
          <Route path='/blog/ai' element={<Ai />} />
          <Route path='/blog/web' element={<Web />} />
          <Route path='/blog/iot' element={<Iot />} />
          <Route path='/blog/ml' element={<Ml />} />
          <Route path='/blog/mvp' element={<Mvp />} />
          <Route path='/blog/cybersecurity' element={<Cybersecurity />} />
          <Route path='/blog/cloudcomputing' element={<Cloudcomputing />} />
          <Route path='/blog/saas' element={<Saas />} />
          <Route path='/blog/techadp' element={<Techadp />} />
          <Route path='/blog/code' element={<Code />} />
          <Route path='/blog/blockchain' element={<Blockchain />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
