import React, { useState } from 'react'
import BackTopBar from '../Buttons/BackTopBar'
import BackTop from '../Buttons/BackTop'
import Navbar from '../Navbar/Navbar'
import '../CSS/Privacy/Privacy.css'
import Footer from '../Footer/Footer'

const Refund = () => {
    const [NavSize, SetNavSize] = useState("80%")

    window.addEventListener("load", () => {

        if (window.innerWidth <= 768) {
            SetNavSize("90%")
        } else {
            SetNavSize("80%")

        }
    })
    window.addEventListener("resize", () => {

        if (window.innerWidth <= 768) {
            SetNavSize("90%")
        } else {
            SetNavSize("80%")

        }
    })
    return (
        <>
            <BackTopBar />
            <Navbar width={NavSize} />
            <BackTop />
            <div className='ParentRapper-Privacy'>
                <div style={{height:'50vh'}}>
                    <h2 className=' heading'>Refund and Cancellation</h2>
                    <p className="PrivacyPara">
                        All sales from the website:www.martiancorporation.com final.
                    </p>
                    <p className="PrivacyPara">
                        No cancellation, no Refunds, under any circumstances or of whatsoever nature. We strongly recommend that before payment, our visitors should read all information about our products, services and support given to our clients.
                    </p>
                </div>
                <Footer width="100%" />
            </div>
        </>
    )
}

export default Refund
