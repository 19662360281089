import React from 'react'
import './CSS/style.css'
import CyberImg1 from './Image/Cyber_img1.jpg'
import hackerImage from './Image/hacker_image.jpg'
import LstGt from './Image/less_than_icon.png'
import SecurityAlert from './Image/Security_Alert.jpg'
import securitySpecialist from './Image/security_specialist.jpg'
import Footer from './Footer'

const CyberSecurity = () => {
    document.title = 'We know what is "Cyber Security"? | Martian Blogs'
    return (
        <div class="Martian_Blog_page">

            <div class="previous_page">

                <a href="https://martiancorporation.com/blogs">
                    <img src={LstGt} alt="back-icon" />
                    <h4>
                        Back to Martian Blogs
                    </h4>
                </a>
            </div>

            <header class="header">
                <h2>
                    We know what is "Cyber Security"?
                </h2>

                <div class="social_media">
                    <a href="http://fb.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://linkedin.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z">
                            </path>
                        </svg>
                    </a>
                    <a href="http://x.com">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <path
                                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z">
                            </path>
                        </svg>
                    </a>
                </div>
            </header>



            <div class="main-blog">
                <div class="header_img">
                    <img src={CyberImg1} alt="cloud computing" />
                </div>

                <div class="blog-content">
                    <p class="main-point">
                        What is Cyber Security?
                    </p>

                    <p>
                        Many times we hear the word “ <span class="para-highlight">
                            Cyber Security
                        </span>”, but we don’t know about Cyber Security.
                        Cyber Security is a technology to Secure data or privacy. Now one question appears in your mind
                        “<span class="para-highlight">
                            What is privacy?
                        </span>”, and “<span class="para-highlight">
                            How to maintain our privacy?
                        </span>“. Now explain all things, the first question is
                        What is privacy, Let's see an example ‘when you have a house or any room and there are
                        many confidential data or Files, You don't view these files or data to nobody, you need a
                        gateman or any CCTV to secure those files. Same here Computer is a Room and the whole world is the Internet. You want to
                        secure your data from the whole world, So you need <span class="para-highlight">
                            an internet specialist (Hacker) to secure your computer from the
                            internet
                        </span>. This specialist Role is called <span class="para-highlight">
                            Cyber Security specialist (Hacker)
                        </span>.

                    </p>

                    <p>
                        Now we explain the word details of "Cyber Security", the meaning of "<span class="para-highlight">
                            cyber
                        </span>" denotes the <span class="para-highlight">
                            whole internet technology
                        </span>, and
                        the security meaning secure means secure data.
                    </p>
                    <p>Now let’s talk about the second question “How to maintain our privacy?”. To secure your privacy if you have a smartphone
                        (android, IOS,Microsoft)</p>

                    <div class="flex">
                        <ul>
                            <li>
                                Don’t install any <span class="para-highlight">
                                    third-party application
                                </span> or don’t open any script on the phone.
                            </li>
                            <li>
                                Does’t view unsecured site examples of the unsecured site .
                            </li>
                        </ul>
                        <img src={SecurityAlert} alt="Security-img" />
                    </div>

                    <ul>
                        <li>
                            don’t sign up any unauthorised site and don’t log in.
                        </li>
                        <li>
                            Don’t allow any unwanted permission(you have a camera but this app wants to contact or call logs permission so don’t
                            allow this type of permission).
                        </li>

                        <li>
                            Don’t click any type of free product link.
                        </li>
                    </ul>




                    <p>
                        (Abstraction - The amount of complexity by which a system is viewed or programmed. The higher the
                        level, the less
                        detail. The lower the level, the more detail. The highest level of abstraction is the entire system.
                        The next level
                        would be a handful of components, and so on, while the lowest level could be millions of objects.)
                    </p>

                    <p class="main-point">
                        To secure your privacy if you have a PC or Desktop:
                    </p>

                    <ul>
                        <li>
                            Don’t connect to public WiFi.
                        </li>
                        <li>
                            Enable any virus to keep your Desktop healthy or virus protected.
                        </li>
                    </ul>




                    <p class="main-point">
                        Type Of Hacker:
                    </p>
                    <p>
                        Basically in Internet World, 4 Types of Hacker Let’s explain all Hacker bellow:
                    </p>
                    <div class="flex">
                        <ul>
                            <li>
                                White hat hackers
                            </li>
                            <li>
                                Black hat hackers
                            </li>
                            <li>
                                Gray hat hackers
                            </li>
                        </ul>
                        <img src={hackerImage} alt="hacker_image" />
                    </div>
                    <p>
                        Let’s explain the all hacker's characteristics:
                    </p>
                    <p>
                        <span class="para-highlight">
                            White hat Hackers
                        </span>a type of hackers who provide service to safe the data of an organization or any Company.
                    </p>

                    <p>
                        <span class="para-highlight">
                            Black hat hackers
                        </span>are a type of hackers who thief data from any confidential server or any company or Organization and sell
                        all data to another company or Organization for Satisfaction or Ransome.
                    </p>
                    <p>
                        <span class="para-highlight">
                            Gray hat hacker
                        </span>is a combination of White hat hacker and Black hat hacker that mean who hack or compromise a server to
                        help any organization but they also hack any server for self-satisfaction or any Ransome.
                    </p>


                    <p class="main-point">
                        Cyber Security Specialists:
                    </p>


                    <p>
                        <span class="para-highlight">
                            Penetration Tester (Ethical Hacker)
                        </span>: Due to the ongoing demand for security testing, ethical hackers that can find
                        and fix vulnerabilities in systems and networks are in high demand.
                    </p>


                    <p>
                        <span class="para-highlight">
                            Security Operations Centre (SOC) Analyst
                        </span>: SOC analysts are vital to organisations' cybersecurity strategies because
                        of their role in real-time threat detection and incident response.
                    </p>

                    <div class="flex">
                        <img src={securitySpecialist} alt="security-specialisst" />

                        <p>
                            <span class="para-highlight">
                                Cloud Security Architect
                            </span>: As cloud computing is more widely used, experts who can design and operate safe cloud
                            settings are in high demand.
                        </p>


                    </div>
                    <p>
                        <span class="para-highlight">
                            Security Consultant
                        </span>: Businesses frequently turn to security consultants for professional advice on enhancing their
                        general cybersecurity posture and resolving particular security concerns.
                    </p>

                    <p>
                        <span class="para-highlight">
                            Information security analysts
                        </span>: These specialists are constantly relevant since they play a key role in maintaining
                        and protecting an organization's computer networks and systems.
                    </p>

                    <p>
                        <span class="para-highlight">
                            Incident Responder
                        </span>: Given the ongoing threat of cyberattacks, there is a continued high demand for incident
                        responders who can investigate and reduce cybersecurity incidents.
                    </p>
                    <p>
                        <span class="para-highlight">
                            Application Security Specialist
                        </span>: Specialists that are able to locate and fix security flaws in code are crucial as
                        software applications continue to evolve.
                    </p>
                    <p>
                        <span class="para-highlight">
                            Compliance Officer
                        </span>: Many organisations prioritise compliance with cybersecurity laws and standards, which fuels
                        demand for compliance officers.
                    </p>
                    <p>
                        <span class="para-highlight">
                            Network Security Engineer
                        </span>: Network security engineers are still in demand since protecting network infrastructure is
                        a constant concern.
                    </p>
                    <p>
                        <span class="para-highlight">
                            AI and Machine Learning Security Specialist
                        </span>: The need for experts who can protect these systems from cyber threats
                        is growing as machine learning and artificial intelligence become more common.
                    </p>

                    <p class="main-point">
                        Conclusion:
                    </p>
                    <p>
                        Hence, There are several specialised positions available in the realm of cyber security, each with a particular focus and
                        significance in protecting digital assets and information. The most well-liked cybersecurity specialty typically
                        coincide with <span class="para-highlight">
                            shifting technological fads
                        </span>, <span class="para-highlight">
                            new security risks
                        </span>, and the growing significance of protecting digital
                        environments. In an ever-evolving digital environment, these specialists play a crucial role in defending key
                        infrastructure, people, and organisations against cyber threats. Both aspiring professionals and organisations aiming to
                        strengthen their security posture must keep up with the most recent cybersecurity advances and trends.
                    </p>
                    <p>
                        <span class="para-highlight-last">
                            Do you Ever use Cyber Security ?.
                        </span>
                    </p>

                </div>

            </div>



            <Footer />




        </div>
    )
}

export default CyberSecurity
