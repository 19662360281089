import React from 'react'
import '../CSS/WhoWeAre/WhoWeAre.css'
import Navbar from '../Navbar/Navbar'
import CardImage from "../../Images/Image/about-card-image1.png"
import HowWeWork from "../../Images/Image/how-we-work.png"
import Footer from '../Footer/Footer'
import AboutHeaderImg from "../../Images/Image/AboutHeaderImg.png"
import BackTopBar from '../Buttons/BackTopBar'
import BackTop from '../Buttons/BackTop'
const WhoWeAre = () => {
  return (
    <>
      <BackTopBar />
      <BackTop />
      <Navbar width="80%" />
      <div className='ParentRapper' id='Whoweare'>
        <section className="aboutUsPage">
          <div className="aboutRapper">


            <div className="aboutHeader" style={{ "--aboutUs-header-img": `url(${AboutHeaderImg})` }}>
              <div className="aboutHeaderContent">
                <span>About us</span>
                <h1>Empowering Tech &<br />
                  Fostering Thriving Businesses
                </h1>
              </div>
            </div>
            <div className="aboutExpertise">
              <div className="expartiesCard">
                <h4 className="exerticesHeader">
                  Our people & leadership
                </h4>

                <p className="exerticesContents">
                  At Martian Corporation, our incredible team and visionary leaders are the driving force behind our innovative journey to shape the future of technology.
                </p>

              </div>
              <div className="expartiesCard">
                <h4 className="exerticesHeader">
                  Purpose, mission & values
                </h4>

                <p className="exerticesContents">
                  Our mission is innovation and progress, guided by values of integrity, collaboration, and relentless excellence.
                </p>

              </div>
              <div className="expartiesCard">
                <h4 className="exerticesHeader">
                  Our aspiration
                </h4>

                <p className="exerticesContents">
                  Our aspiration? To boldly innovate, push boundaries, and leave an indelible mark on the world of technology, forging a future beyond imagination.
                </p>
              </div>
              <div className="expartiesCard">
                <h4 className="exerticesHeader">
                  Our governance
                </h4>

                <p className="exerticesContents">
                  Our governance is rooted in transparency, accountability, and ethical leadership. We're committed to guiding our company with integrity and responsible decision-making.
                </p>
              </div>

            </div>
          </div>
          <div className="aboutBodyContent">
            <div className="whoWeAreRapper">
              <div className="aboutBodyCads">
                <div className="aboutBodyCardsmain">
                  <div className="aboutCrdRapper upperRapper">
                    <div className="aboutCardMainLeft">
                      <h2>Who we are</h2>
                      <p>Martian Corporation ignites startup innovation. We're tech enthusiasts who guide startups through the development process, sharing our expertise to empower them. We're driven by the potential of startups, nurturing ideas to fruition. Join us on this exciting tech journey. Martian Corporation: Sparking Startup Innovation. Together, we'll reshape what's possible in tech.</p>
                    </div>
                    <div className="aboutCardMainRight">
                      <div className="aboutContImg" style={{ "--aboutCont-img": `url(${CardImage})` }}>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="whoWeAreRapper">


              <div className="aboutBodyCads">
                <div className="aboutBodyCardsmain">
                  <div className="aboutCrdRapper upperRapper">

                    <div className="aboutCardMainLeft">
                      <h2>How we work</h2>
                      <p>At Martian, we begin by understanding your unique challenges and crafting tailored solutions. We carefully manage budgets and timelines, assemble expert teams, and execute with precision. Feedback guides our continuous improvement, while rigorous testing ensures the best IT solutions. Finally, we deploy refined solutions, ready to fuel your long-term success. At Martian, we're not just working; we're shaping the tech future.</p>
                    </div>

                    <div className="aboutCardMainRight">
                      <div className="aboutContImg" style={{ "--aboutCont-img": `url(${HowWeWork})` }}>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="groupPhotoSection">
              <div className="groupPhotoSectiongroupPhotoSecBody">
                <div className="familySec">
                  <h3 className="familyHeading">Martian Family</h3>
                  <p>“Coming together is a beginning. Keeping together is progress. Working together is success.”
                    <br />
                    Henry Ford</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer width="100%" />
      </div>
    </>
  )
}

export default WhoWeAre