import React, { useEffect, useState } from 'react'
import '../CSS/Home/Home.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import HomeHeaderBack from "../../Images/Icons/Group_Dark.svg"
import Rectangle from "../../Images/Icons/frame.svg"
import appDev from "../../Images/Icons/appDev.svg"
import SoftWareDev from "../../Images/Icons/softwareDev.svg"
import Operation from "../../Images/Icons/operation.svg"
import RoadCanvas from "../../Images/Icons/roadCanva.svg"
import Invester from "../../Images/Icons/invester.svg"
import RAngle from "../../Images/Icons/arrow-right.svg"
import Marketing from "../../Images/Icons/marketing.svg"
import DBSVG from "../../Images/Icons/database.svg"
import ManagementSVG from "../../Images/Icons/management.svg"
import LegalSVG from "../../Images/Icons/legal.svg"
import PenSVG from "../../Images/Icons/pen.svg"
import TechfileSVG from "../../Images/Icons/techfile.svg"
import BlogHeaderCardImg from "../../Images/Image/homeBlogCardImage.jpg"
import Logo from '../../Images/Icons/LogoMartianDark.svg'
import BlogData from "../../data.json"
import { Link } from 'react-router-dom'
import '../CSS/DarkMode/Home/Home.css'
import backVideos from "../../Videos/background.mp4"
import '../CSS/workFlow.css'
import { HashLink } from 'react-router-hash-link'
import BackTopBar from '../Buttons/BackTopBar'
import BackTop from '../Buttons/BackTop'

const Home = () => {
  const [ScrollY, SetScrollY] = useState(0)
  // eslint-disable-next-line
  const [WindowHeight, SetWindowHeight] = useState(0)
  const [TotalDivHeight, SetTotalDivHeight] = useState()
  const [Loader, SetLoader] = useState(false)
  const [ButtonGet, SetButtonGet] = useState(7)
  const [NavSize, SetNavSize] = useState("80%")
  const [TimeLineData, SetimeLineData] = useState({
    TimeDataHeading: "",
    TimeDataDesc: ""
  })


  useEffect(() => {
    // console.log(document.body.scrollHeight)
    let HWWSection = document.getElementsByClassName("HWWSection")[0]
    let extraTimeline = document.getElementsByClassName("extraTimeline")[0]
    let NavbarCont = document.getElementsByClassName("NavbarCont")[0]
    let homeHeroSection = document.getElementsByClassName("homeHeroSection")[0]
    let circletimeline = document.getElementsByClassName("circletimeline")[0]
    let clickers = document.getElementsByClassName("clickers")

    if (-8 > ScrollY - (NavbarCont.clientHeight + homeHeroSection.clientHeight)) {
      SetTotalDivHeight(HWWSection.clientHeight / 6)

      HWWSection.classList.remove("timeLineChange")
      HWWSection.previousSibling.classList.remove("Worktrans")
      HWWSection.removeAttribute("style")
      extraTimeline.removeAttribute("style")

    } else {
      HWWSection.classList.add("timeLineChange")
      HWWSection.previousSibling.classList.add("Worktrans")
      let totalScrolls = (ScrollY - (NavbarCont.clientHeight + homeHeroSection.clientHeight))
      if (0 <= totalScrolls && totalScrolls < TotalDivHeight) {
        circletimeline.setAttribute("style", `transform:rotate(${0}deg)`)
        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Problem Understanding", TimeDataDesc: "At Martian, meticulous problem-understanding serves as the foundational step for building bespoke solutions." })

        SetButtonGet(7)
      } else if (TotalDivHeight <= totalScrolls && totalScrolls < TotalDivHeight * 2) {
        circletimeline.setAttribute("style", `transform:rotate(${45}deg)`)
        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Ideation & Mindmap", TimeDataDesc: "Ideation and mind mapping is the pivotal catalyst for innovative, client-focused solutions." })
        SetButtonGet(6)
      } else if (TotalDivHeight * 2 <= totalScrolls && totalScrolls < TotalDivHeight * 3) {
        circletimeline.setAttribute("style", `transform:rotate(${90}deg)`)
        SetButtonGet(5)
        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Budget & Timeline", TimeDataDesc: "Thorough budget and timeline management are pivotal steps in our organization, ensuring precision and on-time delivery." })
      } else if (TotalDivHeight * 3 <= totalScrolls && totalScrolls < TotalDivHeight * 4) {
        circletimeline.setAttribute("style", `transform:rotate(${135}deg)`)
        SetButtonGet(4)

        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Team Allocation", TimeDataDesc: "Strategic team allocation optimizes expertise and resources for efficient and client-centered project execution" })
      } else if (TotalDivHeight * 4 <= totalScrolls && totalScrolls < TotalDivHeight * 5) {
        circletimeline.setAttribute("style", `transform:rotate(${180}deg)`)

        SetButtonGet(3)

        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Execution", TimeDataDesc: "Execution stands as the zenith of our organization, where precisely crafted strategies materialize with precision and excellence." })
      } else if (TotalDivHeight * 5 <= totalScrolls && totalScrolls < TotalDivHeight * 6) {
        SetButtonGet(2)
        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Feedback", TimeDataDesc: "Soliciting valuable insights from clients post-execution to refine and enhance the quality of our services." })
        circletimeline.setAttribute("style", `transform:rotate(${225}deg)`)
      } else if (TotalDivHeight * 6 <= totalScrolls && totalScrolls < TotalDivHeight * 7) {
        circletimeline.setAttribute("style", `transform:rotate(${270}deg)`)
        SetButtonGet(1)
        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Testing & Approval", TimeDataDesc: "Rigorous evaluation and client endorsement ensure the finalization of our exceptional IT solutions." })
      } else if (TotalDivHeight * 7 <= totalScrolls && totalScrolls < TotalDivHeight * 8) {
        circletimeline.setAttribute("style", `transform:rotate(${315}deg)`)
        SetimeLineData({ ...TimeLineData, TimeDataHeading: "Deployment", TimeDataDesc: "The last critical step, bringing our refined solutions to life for your lasting success…" })
        SetButtonGet(0)
        SetWindowHeight(HWWSection.clientHeight)

      } else {
        HWWSection.classList.remove("timeLineChange")
        HWWSection.setAttribute("style", "display:none")


      }
    }
    // eslint-disable-next-line 

    for (let i = 0; i < clickers.length; i++) {
      if (ButtonGet === i) {
        clickers[ButtonGet].classList.add("activeBtn")
        clickers[i].previousSibling.classList.add("yearInner")
      } else {
        clickers[i].classList.remove("activeBtn")
        clickers[i].setAttribute("style", "dispay:none;")
        clickers[i].previousSibling.classList.remove("yearInner")
      }
    }
    // eslint-disable-next-line
  }, [ScrollY, ButtonGet])

  window.addEventListener("scroll", () => {
    SetScrollY(window.scrollY)

  })

  const Windowload = () => {
    SetLoader(true)
    if (window.innerWidth <= 768) {
      SetNavSize("90%")
    } else {
      SetNavSize("80%")

    }
  }

  /*
  =========================================
  Work flow For Mobile Version 
  =========================================
  */

  useEffect(() => {

    const workflow_marks = document.querySelectorAll(".body .mark");
    const workflow_line = document.querySelector(".left-bar .line");
    function getTop(elem) {
      let top = 0;
      while (elem) {
        top += elem.offsetTop;
        elem = elem.offsetParent;
      }
      return top;
    }

    function setup() {
      const top_space = workflow_marks[0].offsetTop + 20;
      workflow_line.style.marginTop = top_space + "px";
      workflow_line.maxHeight = workflow_marks[workflow_marks.length - 1].offsetTop + 20 - top_space;
    }

    function updateWorkflow() {
      let pageTop = document.scrollingElement.scrollTop;
      let top = pageTop + window.innerHeight * 0.7;
      workflow_line.style.height = Math.min(Math.max(0, top - getTop(workflow_line)), workflow_line.maxHeight) + "px";
      for (let mark of workflow_marks) {
        if (getTop(mark) < top) mark.parentElement.classList.add("visible");
        else mark.parentElement.classList.remove("visible");
      }
    }

    //updateWorkflowLine();
    window.onscroll = updateWorkflow;
    setup();
    window.onresize = setup;
  })
  window.addEventListener("resize", () => {
    if (window.innerWidth <= 700) {
      SetNavSize("90%")
    } else {
      SetNavSize("80%")

    }

  })
  /*
  ===============================================
  End
  ===============================================
  */

  return (
    <>
      <BackTopBar />
      <BackTop />
      <div className={Loader ? "d-n" : "loader"}>
        <img src={Logo} alt="" />
      </div>
      <Navbar width={NavSize} />
      <section className="homePageSection" onLoad={() => Windowload()}>
        <div className="CircleBackVideo" style={{ "--backgroundVideo": `url(${backVideos})` }}>
          <video src={backVideos} autoPlay loop muted></video>
        </div>
        <div className="homeHeroSection">


          <div className="homeHeaderSec" style={{ "--header-background": `url(${HomeHeaderBack})` }}>

            <div className="headerRapper">

              <div className="homeHeaderContent">
                <div className="headerBars headerBarsTop"></div>
                <div className="headerBars headerBarsBottom"></div>
                <div className="headerBars headerBarsRight"></div>
                <h1>Building Tech
                  <br />
                  & Businesses</h1>
                <p>Introducing the Martian Corporation, where innovation knows no bounds. We are the architects of
                  tomorrow, leveraging cutting-edge technology to construct a diverse portfolio of businesses
                  while empowering others to embark on their own entrepreneurial journeys. At Martian possibilities are limitless and progress is boundless, where the future begins today.</p>
                <div className="headerBtnSec">
                  <a href="/#" style={{ "--header-btn-color": "white", "--header-btn-color-txt": "black" }}>See our work</a>
                  <Link to="/contact" style={{ "--header-btn-color": "#2251FF", "--header-btn-color-txt": "white" }}>Get on a Call</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="trandingbar">
            <h1 className="trandingTitle">TRENDING
              <br />
              INSIGHTS</h1>
            <div className="trandingRapper">
              <h1 className="innerTitleTranding">TRENDING
                <br />
                INSIGHTS</h1>

              <div className="trandingCards">
                <div className="CardCountNum">1</div>
                <div className="trandingCardTitle">Revolutionizing business: the impact of technology adoption</div>

              </div>
              <div className="trandingCards">
                <div className="CardCountNum">2</div>
                <div className="trandingCardTitle">The Future of IoT in 2023 and Beyond</div>

              </div>
              <div className="trandingCards">
                <div className="CardCountNum">3</div>
                <div className="trandingCardTitle">What is the Ultimate purpose of creating an MVP?</div>

              </div>
              <div className="trandingCards">
                <div className="CardCountNum">4</div>
                <div className="trandingCardTitle">LET’S Know SaaS, PaaS and IaaS</div>

              </div>
            </div>
            <div className="trandingRapper">
              <div className="trandingCards">
                <div className="CardCountNum">1</div>
                <div className="trandingCardTitle">Revolutionizing business: the impact of technology adoption</div>

              </div>
              <div className="trandingCards">
                <div className="CardCountNum">2</div>
                <div className="trandingCardTitle">The Future of IoT in 2023 and Beyond</div>

              </div>
              <div className="trandingCards">
                <div className="CardCountNum">3</div>
                <div className="trandingCardTitle">What is the Ultimate purpose of creating an MVP?</div>

              </div>
              <div className="trandingCards">
                <div className="CardCountNum">4</div>
                <div className="trandingCardTitle">LET’S Know SaaS, PaaS and IaaS</div>

              </div>
            </div>

          </div>
        </div>

        <div className="FullAnimSec">
          <h1 className='howWeWorkQuote'>How We Work</h1>
          <div className="HWWSection">


            <div className="timeLine">


              <div className="earth">
              </div>
              <div className="circletimeline">
                <span className="circletimelineDots" style={{ "--i": "1" }}>
                  <span className='dots'>
                    <div className='timelineItems'>
                      <span className="year">8</span>
                      <span className="clickers"></span>
                    </div></span>
                </span>
                <span className="circletimelineDots" style={{ "--i": "2" }}>
                  <span className='dots'>
                    <div className='timelineItems'>
                      <span className="year">7</span>
                      <span className="clickers"></span>
                    </div></span>
                </span>
                <span className="circletimelineDots" style={{ "--i": "3" }}>
                  <span className='dots'><div className='timelineItems'>
                    <span className="year">6</span>
                    <span className="clickers"></span>

                  </div> </span>
                </span>
                <span className="circletimelineDots" style={{ "--i": "4" }}>
                  <span className='dots' >
                    <div className='timelineItems'>

                      <span className="year">5</span>
                      <span className="clickers"></span>
                    </div></span>
                </span>
                <span className="circletimelineDots" style={{ "--i": "5" }}>
                  <span className='dots' >
                    <div className='timelineItems'>
                      <span className="year">4</span>
                      <span className="clickers"></span></div> </span>
                </span>
                <span className="circletimelineDots" style={{ "--i": "6" }}>
                  <span className='dots'>
                    <div className='timelineItems'>
                      <span className="year">3</span>
                      <span className="clickers"></span>
                    </div> </span>
                </span>
                <span className="circletimelineDots" style={{ "--i": "7" }}>
                  <span className='dots'>
                    <div className='timelineItems'>
                      <span className="year">2</span>
                      <span className="clickers"></span>
                    </div> </span>
                </span>
                <span className="circletimelineDots" style={{ "--i": "8" }}>
                  <span className='dots'>
                    <div className='timelineItems'>
                      <span className="year">1</span>
                      <span className="clickers"></span>
                    </div> </span>
                </span>
              </div>
              <div className="detilSec">
                <div className="timelineDetailCard">
                  <div className="timelineDescSec">
                    {TimeLineData.TimeDataDesc}
                  </div>
                  <div className="timelineHeadingSec">
                    <h1>{TimeLineData.TimeDataHeading}</h1>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="extraTimeline"></div>
          {/* How We Work Mobile version */}

          <main id="workflow">
            <div className="container">
              <div className="headingWorkFlow">

                <h1 className="head">How We Work</h1>
              </div>
              <div className="Rapper">


                <div className="body">
                  <div className='WorkFlowCards'>
                    <button className="mark">1</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021b17571cd4ae44e6d9c_Discovery.svg"
                        alt="holla" />
                      <div className="contentText">
                        <h2>Problem Understanding</h2>
                        <p>At Martian, we start by deeply grasping the issue, setting the stage for tailored solutions.</p>
                      </div>
                    </div>
                  </div>
                  <div className='WorkFlowCards'>
                    <button className="mark">2</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021c965a10f354f44eb4d_UX%20Design.svg"
                        alt="holla" />
                      <div className="contentText">
                        <h2>Ideation & Mindmap</h2>
                        <p>Brainstorming and idea generation are the sparks behind innovative, client-centered solutions.</p>
                      </div>
                    </div>
                  </div>
                  <div className='WorkFlowCards'>
                    <button className="mark">3</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021c9d6ef16e8c25d3836_Webflow%20Dev.svg"
                        alt="holla" />
                      <div className="contentText">
                        <h2>Budget & Timeline</h2>
                        <p>We handle budgets and schedules with precision, ensuring on-time delivery.</p>
                      </div>
                    </div>
                  </div>
                  <div className='WorkFlowCards'>
                    <button className="mark">4</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021c9e0266c13ee81631c_Visual%20Design.svg"
                        alt="holla" />
                      <div className="contentText">
                        <h2>Team Allocation</h2>
                        <p>Strategic team selection optimizes expertise and resources, ensuring efficient, client-focused project execution.</p>
                      </div>
                    </div>
                  </div>
                  <div className='WorkFlowCards'>
                    <button className="mark">5</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021c9676fe14d8c174d93_Onboarding.svg" alt="holla" />
                      <div className="contentText">
                        <h2>Execution</h2>
                        <p>Execution is where our carefully crafted strategies come to life with precision and excellence.</p>
                      </div>
                    </div>
                  </div>
                  <div className='WorkFlowCards'>
                    <button className="mark">6</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021c9676fe14d8c174d93_Onboarding.svg" alt="holla" />
                      <div className="contentText">
                        <h2>Feedback</h2>
                        <p>We value your insights after execution, using them to refine and enhance our services.</p>
                      </div>
                    </div>
                  </div>
                  <div className='WorkFlowCards'>
                    <button className="mark">7</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021c9676fe14d8c174d93_Onboarding.svg" alt="holla" />
                      <div className="contentText">
                        <h2>Testing & Approval</h2>
                        <p>Rigorous evaluation and client approval ensure the finalization of our exceptional IT solutions.</p>
                      </div>
                    </div>
                  </div>
                  <div className='WorkFlowCards'>
                    <button className="mark">8</button>
                    <div className="content">
                      <img src="https://assets.website-files.com/5ff65128d64d8f034c3f54ff/600021c9676fe14d8c174d93_Onboarding.svg" alt="holla" />
                      <div className="contentText">
                        <h2>Deployment</h2>
                        <p>The final step is bringing our refined solutions to life, ensuring your long-term success.</p>
                      </div>
                    </div>
                  </div>
                  <aside className="left-bar">
                    <div className="line"></div>
                  </aside>
                </div>
              </div>
            </div>
          </main>

          {/* How we Work Mobile Version End  */}
        </div>
        <div className="serviceCardSection">

          <div className="homeServices" id='services'>
            <div className="homeServicesRapper">
              <h1>SERVICES</h1>
              <div className="homeServicesCards">
                <div className="homeServiceCard">
                  <img src={Rectangle} alt="" />
                  <h1>Web Development</h1>
                  <p>Expert website development service delivering tailored, user-friendly, and responsive websites with cutting-edge features and functionalities.</p>
                  <Link to="#">Learn more</Link>
                </div>
                <div className="homeServiceCard">
                  <img src={appDev} alt="" />
                  <h1>Application Development</h1>
                  <p>End-to-end application development service specializing in crafting intuitive,high-performance software for your industry and goals.</p>
                  <Link to="#">Learn more</Link>
                </div>
                <div className="homeServiceCard">
                  <img src={SoftWareDev} alt="" />
                  <h1>Software Development</h1>
                  <p>Comprehensive software development service building innovative, scalable, and secure solutions to meet your unique business needs.</p>
                  <Link to="#">Learn more</Link>
                </div>

              </div>
              <Link to="#" className='serviceExpore'><span>EXPLORE ALL SERVICES</span>
                <img src={RAngle} alt="" />

              </Link>
            </div>
          </div>

          <div className="homePageCardsSection" id='products'>
            <div className="homePageCardsRapper">
              <h1 className="productheading">PRODUCTS</h1>
              <div className="homePageCards">
                <div className="homePageCard">
                  <img src={RoadCanvas} alt="" id='roadCanvas' />
                  <div className="cardContentRapper">
                    <h1>RoadCanvas</h1>
                    <p>Drive Your Brand to New Heights with Mobile Billboards.</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>

                <div className="homePageCard">
                  <img src={ManagementSVG} alt="" />
                  <div className="cardContentRapper">
                    <h1>Patient Management System</h1>
                    <p>Efficient patient management system streamlining healthcare operations, enhancing patient care, and optimizing medical practice workflows.</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
                <div className="homePageCard">
                  <img src={DBSVG} alt="" />
                  <div className="cardContentRapper">
                    <h1>Database Management System</h1>
                    <p>Cloud-based Database Management System (DBMS) SaaS offering seamless data storage, organization, and retrieval with scalability.</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
              </div>
              <Link to="#" className='serviceExpore'><span>EXPLORE ALL PRODUCTS</span>
                <img src={RAngle} alt="" />
              </Link>
            </div>
          </div>

          <div className="homePageCardsSection" id='sum'>
            <div className="homePageCardsRapper">
              <h1 className="productheading">STARTUPS UNDER MANAGEMENT</h1>
              <div className="homePageCards">
                <div className="homePageCard">
                  <img src={LegalSVG} alt="" />
                  <div className="cardContentRapper">
                    <h1>Legal</h1>
                    <p>SPA, eCommerce, SaaS, data-driven products, enterprise portals, and API platforms</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
                <div className="homePageCard">
                  <img src={PenSVG} alt="" />
                  <div className="cardContentRapper">
                    <h1>Brand Design</h1>
                    <p>Cloud-first apps, containerization, microservices, and managed services</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
                <div className="homePageCard">
                  <img src={TechfileSVG} alt="" />
                  <div className="cardContentRapper">
                    <h1>Tech Support</h1>
                    <p>Validate your strategy, acquire early users and test product-market fit in weeks</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
              </div>
              <div className="homePageCards">
                <div className="homePageCard">
                  <img src={Marketing} alt="" />
                  <div className="cardContentRapper">
                    <h1>Marketing & Strategy</h1>
                    <p>Propelling growth through data-driven strategies, innovative campaigns, and market leadership tactics.</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
                <div className="homePageCard">
                  <img src={Operation} alt="" />
                  <div className="cardContentRapper">
                    <h1>Operations</h1>
                    <p>Enhancing efficiency, driving growth, and ensuring a solid foundation for success.</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
                <div className="homePageCard">
                  <img src={Invester} alt="" />
                  <div className="cardContentRapper">
                    <h1>Investor Networking</h1>
                    <p>We connect startup dreamers with the cosmic capital and wisdom for stellar success.</p>
                    <Link to="#">Learn more</Link>
                  </div>
                </div>
              </div>
              <Link to="#" className='serviceExpore'><span>EXPLORE ALL PRODUCTS</span>
                <img src={RAngle} alt="" />

              </Link>
            </div>
          </div>

          <div className="blogPageHeader">
            <h1>Let’s Talk Business</h1>
            <Link to="/contact">
              <span>Contact us</span>
              <img src={RAngle} alt="" />
            </Link>
          </div>
          <div className="blogCardHeader">
            <div className="blogHeaderCardLeft"></div>
            <div className="blogHeaderContent">
              <HashLink to='/career#Career'>
                <h1>Join Martian</h1>
              </HashLink>
              <HashLink to='/career#Career'>
                <span>Careers</span>
              </HashLink>
              <HashLink to='/career#Career'>
                <span>Search and apply</span>
              </HashLink>
            </div>
            <img src={BlogHeaderCardImg} alt="" className='blogHeaderCardRight' />
          </div>

          <div className="HomePageBlog">
            <p className="newMartian">NEW AT MARTIAN</p>
            <div className="homeBlogContentSection">

              <Link className="blogCardHomeSection" target='_blank' to={BlogData[0].linkto}>
                <img src={BlogData[0].image} alt="" className='blogImage' />
                <div className="homeBlogContent">
                  <p className='blogHeaderDate'>{BlogData[0].date}</p>
                  <h1 className='homeBlogTitle'>{BlogData[0].title}</h1>
                </div>
              </Link>



              <div className="blogCardHomeSection">
                <Link to={BlogData[1].linkto} target='_blank' className="SecondCardUpper">
                  <div className="ImgRapper">
                    <img src={BlogData[1].image} alt="" />
                  </div>
                  <div className="secondBlogContent">
                    <p className="homeBlogDate">{BlogData[1].date}</p>
                    <h1 className="homeBlogTitle">{BlogData[1].title}</h1>
                  </div>
                </Link>
                <Link to={BlogData[2].linkto} target='_blank' className="secondCardLower">
                  <p className="homeBlogDate">{BlogData[2].date}</p>
                  <h1 className="homeBlogTitle">{BlogData[2].title}</h1>
                </Link>
              </div>
              <div className="blogCardHomeSection">
                <div className="imageRapper">

                  <img src={BlogData[3].image} alt="" />
                </div>
                <Link to={BlogData[3].linkto} target='_blank' className="homeBlogContent">
                  <p className='homeBlogDate'>{BlogData[3].date}</p>
                  <h1 className='homeBlogTitle'>{BlogData[3].title}</h1>
                </Link>


              </div>
              <Link to={BlogData[4].linkto} target='_blank' className="blogCardHomeSection">
                <div className="imageRapper">
                  <img src={BlogData[4].image} alt="" />
                </div>

                <div className="homeBlogContent">
                  <Link to={BlogData[4].linkto} target='_blank' className="lastblogcardcontents">

                    <p className='homeBlogDate'>{BlogData[4].date}</p>
                    <h1 className='homeBlogTitle'>{BlogData[4].title}</h1>
                  </Link>
                  <HashLink to="/blogs#BackTop" className='BlogReadMore'>Read more on our blog</HashLink>
                </div>
              </Link>


            </div>
          </div>


        </div>

      </section >
      <Footer width={NavSize} />

    </>
  )
}

export default Home