import { React, useEffect, useState } from 'react'
import "../CSS/Career/SearchJobs.css"
import Navbar from '../Navbar/Navbar'
import SearchIcon from "../../Images/Icons/search.svg"
import NextBtn from "../../Images/Icons/angle-right.svg"
import PrevBtn from "../../Images/Icons/angle-left.svg"
import Footer from '../Footer/Footer'

const SearchJobs = () => {

  let [Card, SetCard] = useState()
  useEffect(() => {
    SetCard(document.getElementsByClassName("jobResultesCards").length)
  }, [])

  return (
    <>
      <Navbar />
      <section className="jobSearchSection">
        <div className="jobRapper">
          <div className="jobSearchHeader">


            <div className="searchInput">
              <input type="text" placeholder='Search Jobs' />
              <button type='button'><img src={SearchIcon} alt="" /></button>
              <div className="searchOptions">
                <select name="department" id="department" className='searchOptionSelector'>
                  <option>Department</option>
                  {/* <option>Department</option> */}
                  {/* <option></option> */}
                </select>
                <select name="department" id="department" className='searchOptionSelector'>
                  <option>Locations</option>
                </select>
                <select name="department" id="department" className='searchOptionSelector'>
                  <option>Job Type</option>
                </select>
                <select name="department" id="department" className='searchOptionSelector'>
                  <option>Remote Eligible</option>
                </select>
              </div>
            </div>
          </div>


        </div>
        <div className="searchedContainBody">
          <div className="searchedContain">
            <div className="jobSearchFilter">
              <div className="jobResults">{
                Card
              } Results</div>
              <div className="jobShortBtn"><span>Short By</span><select name="jobFilter" id="jobFilter"><option>Relevance</option></select></div>
            </div>

            <div className="jobResultesCards">
              <div className="role">Sales Development Representative</div>
              <div className="jobCatagory">
                <span className='jobSearchCardHeading'>Categories</span>
                <p>Business Development</p>
              </div>
              <div className="jobLocation">
                <span className='jobSearchCardHeading'>Location</span>
                <p>Sydney, New South Wales
                  Australia</p>
              </div>
            </div>
            <div className="jobResultesCards">
              <div className="role">Sales Development Representative</div>
              <div className="jobCatagory">
                <span className='jobSearchCardHeading'>Categories</span>
                <p>Business Development</p>
              </div>
              <div className="jobLocation">
                <span className='jobSearchCardHeading'>Location</span>
                <p>Sydney, New South Wales
                  Australia</p>
              </div>
            </div>
            <div className="jobResultesCards">
              <div className="role">Sales Development Representative</div>
              <div className="jobCatagory">
                <span className='jobSearchCardHeading'>Categories</span>
                <p>Business Development</p>
              </div>
              <div className="jobLocation">
                <span className='jobSearchCardHeading'>Location</span>
                <p>Sydney, New South Wales
                  Australia</p>
              </div>
            </div>
            <div className="jobResultesCards">
              <div className="role">Sales Development Representative</div>
              <div className="jobCatagory">
                <span className='jobSearchCardHeading'>Categories</span>
                <p>Business Development</p>
              </div>
              <div className="jobLocation">
                <span className='jobSearchCardHeading'>Location</span>
                <p>Sydney, New South Wales
                  Australia</p>
              </div>
            </div>

            {/* page count section */}

            <div className="pageCounterSection">
              <p><span>Items per page</span><select name="pageCount" id="pageCount"><option value="0">0</option></select></p>
              <div className="pageStatus">1 – 10 of 187</div>
              <div className="pageChangerBtn">
                <button className="next"><img src={PrevBtn} alt="" /></button>
                <button className="prev"><img src={NextBtn} alt="" /></button>
              </div>

            </div>
          </div>

        </div>
      </section>
      <Footer />


    </>
  )
}

export default SearchJobs