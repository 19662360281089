import React from 'react'
import "../CSS/Blogs/Cards.css"
import { Link } from 'react-router-dom'
import "../CSS/DarkMode/Blogs/Cards.css"
function Cards(cardData) {
    return (
        <div className="card">
            <Link to={cardData.linkto} target='_blank'>
                <div className="cardImage">

                    <img src={cardData.image} alt="" />
                </div>
            </Link>
            <div className="cardContaint">
                <Link to={cardData.linkto} target='_blank'>
                    <h1>{cardData.title}</h1>
                </Link>
                <p className="cardUploadTime">{cardData.date}</p>
                <Link to={cardData.linkto} target='_blank' className='Para'>
                    <p>{cardData.desc.slice(0, 400)} ...</p>
                </Link>
                <div className="blogTags">
                    <Link to="/blogs">Technology</Link>
                </div>
            </div>
        </div>)
}

export default Cards